/*eslint-disable*/
import React from "react";
import moment from "moment/moment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import red from "@material-ui/core/colors/red";
import AlertDialogSlide from "components/AlertDialogSlide";
import { userBuyPluginsRequest } from "redux/actions/PlanActions";
import { connectAutoDispatch } from "redux/reduxConnect";
import { findIndex } from "lodash";
import { withSnackbar } from "notistack";
import { apiGetCheckoutUrl, apiUpdateSubscription } from "api";

const styles = theme => ({
  titles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .plugin-title": {
      fontSize: "0.7em"
    },
    "& .plugin-price": {
      textAlign: "right",
      fontSize: "0.8em",
      "& .has-sale-price": {
        textDecoration: "line-through",
        fontSize: "0.7em"
      },
      "& .sale-price": {
        fontSize: "0.8em"
      }
    }
  },
  card: {},
  media: {
    paddingTop: "77.25%"
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500],
    color: "#fff",
    fontSize: 12,
    width: 55,
    height: 55
  }
});

class PluginsUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isDisable: false,
      isOpenCancelDialog: false,
      isOpenCancelDialogExtraLogins: false,
      isOpenWarningDialogSubscribeAZPRO: false,
      openCheckoutDialog: false
    };
  }

  componentDidUpdate(prevProps) {
    const { planState } = this.props;
    if (
      prevProps &&
      this.props &&
      prevProps.item &&
      this.props.item &&
      prevProps.item.isBought === false &&
      this.props.item.isBought === true &&
      this.props.item.isFree === false
    ) {
      this.setState({ isDisable: false });
    }

    if (
      prevProps.planState.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      planState.errorMessage.code
    ) {
      this.setState({ isDisable: false });
    }

    if (
      prevProps.planState.callingStartTrialPlugin &&
      !planState.callingStartTrialPlugin &&
      !planState.errorMessage.code &&
      prevProps.item._id === this.state.id
    ) {
      this.props.enqueueSnackbar(
        <span>
          Successfully started the trial of the Plugin <strong>{this.props.item.title}</strong>
        </span>,
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 2000
        }
      );
    }
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  updateSubscription = async () => {
    try {
      const { item } = this.props;
      if (item.stripeSubId) {
        this.setState({
          isDisable: true,
          isOpenCancelDialog: false,
          isOpenCancelDialogExtraLogins: false
        });
        await apiUpdateSubscription({
          subId: item.stripeSubId,
          cancel: !item.isCancel
        });
      }
    } catch (e) {
      this.setState({
        isDisable: false
      });
    }
  };

  renderOpenCancelDialog() {
    const { item } = this.props;
    return (
      <AlertDialogSlide
        isHtml
        open
        isDisagree
        isAgree
        handleClose={() => {}}
        handleDisagre={() => this.setState({ isOpenCancelDialog: false })}
        handleAgree={this.updateSubscription}
        title="Are you sure you want to deactivate this plugin ?"
        content={`
      <div>
        <p>By deactivating this plugin you will lose access to the features it provides. If this is a paid plugin, the subscription will be terminated and all the data of the plugin will be removed at the end of the billing cycle. You will still be able to re-enable and use the plugin at any time until the end of the billing cycle.</p>
      </div>
		`}
        textDisagree="No"
        textAgree="Yes"
      />
    );
  }

  renderOpenCancelDialogExtraLogins() {
    const { item } = this.props;
    return (
      <AlertDialogSlide
        isHtml
        open
        isDisagree
        isAgree
        handleClose={() => {}}
        handleDisagre={() => this.setState({ isOpenCancelDialogExtraLogins: false })}
        handleAgree={this.updateSubscription}
        title="Cancel my subscription"
        content={`
      <div>
        <p>If you cancel you may lose the ability to sign back up at your current price.<br />Are you sure you would like to cancel?</p>
        <p style="color:red">
            Note: We will delete your sub-accounts randomly at the time this subscription expires. To avoid it, you can delete those sub-accounts that you don't want anymore in advance
        </p>
      </div>
		`}
        textDisagree="No"
        textAgree="Yes"
      />
    );
  }

  renderOpenDialogSubscribeAZPRO() {
    return (
      <AlertDialogSlide
        isHtml
        open
        isDisagree
        isAgree
        handleClose={() => {}}
        handleDisagre={() => {
          this.setState({ isOpenWarningDialogSubscribeAZPRO: false });
        }}
        handleAgree={() => {
          this.setState({ isOpenWarningDialogSubscribeAZPRO: false });
          window.open(process.env.PLUGIN_AZ_PRO_URL, "_blank");
        }}
        content={`
      <div>
       <p>
            Please subscribe Advanced Plan plan first to be able to subscribe this plugin
       </p>
      </div>
		`}
        textDisagree="No"
        textAgree="Yes"
      />
    );
  }

  onBuyPlugin = item => () => {
    if (item.code === "BULK_CHECKER_PRO") {
      if (this.props.currentUserData.productType !== "basic") {
        return this.setState({ isOpenWarningDialogSubscribeAZPRO: true });
      }
    }
    const {
      currentUserData: { contactId, firstName, lastName, email }
    } = this.props;
    const { isFree, paymentUrl } = item;
    this.setState({ isDisable: true }, () => {
      if (isFree === true) {
        this.props.userBuyPluginsRequest({ pluginId: item._id, type: "add" });
      } else {
        this.openCheckoutUrl(item);
      }
    });
  };

  openDiaLogCancel = item => () => {
    if (
      ["AZINSIGHT_PLUS_ADDITIONAL_LOGIN", "AZINSIGHT_PRO_ADDITIONAL_LOGIN"].indexOf(item.code) >= 0
    ) {
      return this.setState({ isOpenCancelDialogExtraLogins: true });
    } else if (["MANAGE_BRAND", "AZINSIGHT_AZNOTES"].includes(item.code)) {
      return this.setState({ isOpenCancelDialog: true });
    }
  };

  onStartTrial = item => () => {
    this.setState({ isDisableButtonTrial: true, id: item._id });
  };

  _renderButtonTrial = () => {
    const { userInfo, item } = this.props;
    if (!item.allowFreeTrial) return <span />;
    const index = findIndex(userInfo.listPluginTrial, { parent: item.code });
    const hasStartTrial = index >= 0;
    const trialInfo = userInfo.listPluginTrial[index];
    const { isDisableButtonTrial } = this.state;
    if (!hasStartTrial) {
      return (
        <Button
          disabled={isDisableButtonTrial}
          variant="outlined"
          color="primary"
          size="small"
          onClick={this.onStartTrial(item)}
          style={{ marginLeft: 10 }}
        >
          Start free trial
        </Button>
      );
    }

    if (trialInfo.isEndTrial) {
      return (
        <Button disabled style={{ color: "#4caf50" }}>
          Completed free trial
        </Button>
      );
    }

    return (
      <Button disabled style={{ color: "#4caf50" }}>
        In free trial
      </Button>
    );
  };

  deactiveFreePlugin = item => () => {};

  openCheckoutUrl = async item => {
    const resp = await apiGetCheckoutUrl({
      price: item.priceId,
      mode: item.mode,
      quantity: 1
    });
    if (resp.url) {
      // window.open(resp.url, "_blank");
      this.setState({
        checkoutUrl: resp.url,
        openCheckoutUrl: true
      });
    }
  };

  closeCheckoutDialog = () => {
    this.setState({
      checkoutUrl: null,
      openCheckoutUrl: false
    });
  };

  render() {
    let {
      classes,
      currentUserData: { _id, contactId, firstName, lastName, email, productType },
      item: {
        title,
        endCircleAt,
        price,
        salePrice,
        interval,
        imgUrl,
        introduce,
        content,
        isCancel,
        isBought,
        isFree,
        unLimited,
        pluginSubscribedAt,
        paymentUrl,
        code
      },
      item,
      handleShowSnackBar,
      userInfo
    } = this.props;
    const {
      isDisable,
      isOpenCancelDialog,
      isOpenCancelDialogExtraLogins,
      isOpenWarningDialogSubscribeAZPRO,
      openCheckoutDialog
    } = this.state;
    const txtRice = isFree === true ? <span>Free</span> : null;
    if (isFree === true && productType === "hazbasic") {
      isBought = false;
    }
    if (code === "AZALERT_EXTRA_LOGIN") {
      isBought = false;
    }
    return (
      <Card className={classes.card + " pp-plans"}>
        <CardHeader
          avatar={
            txtRice && (
              <Avatar aria-label="Recipe" className={classes.avatar}>
                <b>{txtRice}</b>
              </Avatar>
            )
          }
          title={
            isFree ? (
              title
            ) : (
              <div className={classes.titles}>
                <div className="plugin-title">{title}</div>
                <div className="plugin-price">
                  <div className={salePrice ? "has-sale-price" : ""}>
                    ${price.toFixed(2)} / {interval}
                  </div>
                  {salePrice && (
                    <div className="sale-price">
                      SALE: ${salePrice.toFixed(2)} / {interval}
                    </div>
                  )}
                </div>
              </div>
            )
          }
          subheader={""}
          style={{ padding: 10 }}
        />
        <CardContent>
          <Typography component="p">
            {content}
            <br />
            {!isFree && endCircleAt ? (
              <b>Next billing date: {moment(endCircleAt).format("MMM DD, YYYY")}</b>
            ) : (
              ""
            )}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          {unLimited === true && isBought === true && !isFree ? (
            <Button disabled style={{ color: "#4caf50" }}>
              Added
            </Button>
          ) : isFree === false && isBought === true ? (
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Button
                disabled={isCancel || isDisable}
                onClick={this.openDiaLogCancel(item)}
                variant="outlined"
                color="secondary"
                size="small"
              >
                {isCancel === true
                  ? "Canceled. No additional payments due."
                  : "Cancel Subscription"}
              </Button>

              {!isCancel && (
                <div>
                  <Button disabled style={{ color: "#4caf50" }}>
                    Subscribed
                  </Button>
                  <div style={{ color: "#4caf50", fontWeight: "bold" }}>
                    on {moment(pluginSubscribedAt).format("ll")}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {!isBought && (
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={this.onBuyPlugin(item)}
                  >
                    Get it now
                  </Button>
                  {this._renderButtonTrial()}
                </div>
              )}
              {isBought && isFree && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={this.openDiaLogCancel(item)}
                  >
                    DEACTIVATE
                  </Button>

                  <Button disabled style={{ color: "#4caf50" }}>
                    Added
                  </Button>
                </div>
              )}
            </>
          )}
        </CardActions>
        {isOpenCancelDialog ? this.renderOpenCancelDialog() : ""}
        {isOpenCancelDialogExtraLogins ? this.renderOpenCancelDialogExtraLogins() : ""}
        {isOpenWarningDialogSubscribeAZPRO ? this.renderOpenDialogSubscribeAZPRO() : ""}
        <Dialog open={openCheckoutDialog} onClose={this.closeCheckoutDialog}>
          <DialogContent>
            {this.state.checkoutUrl ? <iframe src={this.state.checkoutUrl} /> : null}
          </DialogContent>
        </Dialog>
      </Card>
    );
  }
}

export default connectAutoDispatch(
  ({ planState, userState: { userInfo } }) => ({
    planState,
    userInfo
  }),
  {
    userBuyPluginsRequest
  }
)(withSnackbar(withStyles(styles)(PluginsUI)));
