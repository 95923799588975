/*eslint-disable*/
import axios from "utils/axios";
import CustomError from "api/user/CustomError";
import ProcessError from "api/user/ProcessError";

const baseURL = `${process.env.AZ_API_URL_NEW}/portal`;

export const apiGetListPlan = payload => {
  return axios({
    method: "GET",
    baseURL,
    url: "/get-current-plans"
  })
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiGetCurrentPlugins = payload => {
  return axios({
    method: "GET",
    baseURL,
    url: "/get-current-plugins"
  })
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiGetCurrentPluginsAdded = payload => {
  return axios({
    baseURL,
    method: "GET",
    url: "/get-current-plugins-added"
  })
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserBuyPlugins = payload => {
  return axios({
    baseURL,
    method: "POST",
    url: "/user-add-plugin",
    data: payload
  })
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiGetPlans = payload => {
  return axios({
    baseURL,
    method: "GET",
    url: `/get-plans/${payload.parent}`
  })
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};
