import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import logger from "redux-logger";
import constants from "utils/constants";
import { userInitState } from "./reducers/UserReducer";
import reducers from "./reducers";
import rootSagas from "./saga";

const sagaMiddleware = createSagaMiddleware();

const syncLocalUserAccount = next => (reducer, initialState, enhancer) => {
  let persistedState;
  let finalInitialState;
  try {
    persistedState = {
      userState: {
        ...userInitState,
        userInfo: JSON.parse(localStorage.getItem(constants.KEY_LOCAL_STORAGE))
      }
    };
    finalInitialState = { ...initialState, ...persistedState };
  } catch (e) {
    console.warn("Failed to retrieve initialize state from localStorage:", e);
  }
  const store = next(reducer, finalInitialState, enhancer);
  return store;
};

const createEnhancer = () => {
  const composeEnhancers =
    process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const middlewares =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(sagaMiddleware)
      : applyMiddleware(sagaMiddleware, logger);
  return composeEnhancers(syncLocalUserAccount, middlewares);
};

function createRedux(initialState = {}) {
  const enhancer = createEnhancer();

  const store = createStore(reducers, initialState, enhancer); // Hydrate the store with server-side data
  sagaMiddleware.run(rootSagas);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers/index").default();
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default {
  createRedux
};

export { createRedux };
