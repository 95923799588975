import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 100vh;
  position: relative;
  min-width: fit-content;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)}px;
  position: relative;
`;
