import { PlanActionType } from "redux/actions/PlanActions";
import update from "immutability-helper";
import PropTypes from "prop-types";

export const planPropTypes = {
  callingGetListPlan: PropTypes.bool,
  callingGetCurrentPlugins: PropTypes.bool,
  callingGetCurrentPluginsAdded: PropTypes.bool,
  callingUserBuyPlugin: PropTypes.bool,
  callingStartTrialPlugin: PropTypes.bool,
  bulkCheckerPlans: PropTypes.shape({}),
  asinTrackerPlans: PropTypes.shape({}),
  currentPlans: PropTypes.shape({}),
  currentPlugins: PropTypes.shape({}),
  asinTrackerPlugins: PropTypes.shape({}),
  pluginsAdded: PropTypes.array,
  errorMessage: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  })
};

export const planInitState = {
  callingGetListPlan: false,
  callingGetCurrentPlugins: false,
  callingGetCurrentPluginsAdded: false,
  callingUserBuyPlugin: false,
  callingStartTrialPlugin: false,
  asinTrackerPlans: {
    data: []
  },
  bulkCheckerPlans: {
    data: []
  },
  currentPlans: {
    data: []
  },
  currentPlugins: {
    data: []
  },
  asinTrackerPlugins: {
    data: []
  },
  bcpPlugins: {
    data: []
  },
  pluginsAdded: [],
  errorMessage: {}
};

export default function createReducer(state = planInitState, action) {
  switch (action.type) {
    case PlanActionType.GET_PRODUCT_PLAN_REQUEST:
      return update(state, {
        callingGetListPlan: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_PRODUCT_PLAN_SUCCESS:
      return update(state, {
        callingGetListPlan: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_PRODUCT_PLAN_FAILURE:
      return update(state, {
        callingGetListPlan: { $set: false },
        ...action.payload
      });

    case PlanActionType.GET_BULK_CHECKER_PLAN_REQUEST:
      return update(state, {
        callingGetListPlan: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_BULK_CHECKER_PLAN_SUCCESS:
      return update(state, {
        callingGetListPlan: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_BULK_CHECKER_PLAN_FAILURE:
      return update(state, {
        callingGetListPlan: { $set: false },
        ...action.payload
      });

    case PlanActionType.GET_CURRENT_PLUGINS_REQUEST:
      return update(state, {
        callingGetCurrentPlugins: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_CURRENT_PLUGINS_SUCCESS:
      return update(state, {
        callingGetCurrentPlugins: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_CURRENT_PLUGINS_FAILURE:
      return update(state, {
        callingGetCurrentPlugins: { $set: false },
        ...action.payload
      });

    case PlanActionType.GET_ASIN_TRACKER_PLUGINS_REQUEST:
      return update(state, {
        callingGetCurrentPlugins: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_ASIN_TRACKER_PLUGINS_SUCCESS:
      return update(state, {
        callingGetCurrentPlugins: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_ASIN_TRACKER_PLUGINS_FAILURE:
      return update(state, {
        callingGetCurrentPlugins: { $set: false },
        ...action.payload
      });

    case PlanActionType.GET_CURRENT_PLUGINS_ADDED_REQUEST:
      return update(state, {
        callingGetCurrentPluginsAdded: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_CURRENT_PLUGINS_ADDED_SUCCESS:
      return update(state, {
        callingGetCurrentPluginsAdded: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_CURRENT_PLUGINS_ADDED_FAILURE:
      return update(state, {
        callingGetCurrentPluginsAdded: { $set: false },
        ...action.payload
      });

    case PlanActionType.USER_BUY_PLUGINS_REQUEST:
      return update(state, {
        callingUserBuyPlugin: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.USER_BUY_PLUGINS_SUCCESS:
      return update(state, {
        callingUserBuyPlugin: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.USER_BUY_PLUGINS_FAILURE:
      return update(state, {
        callingUserBuyPlugin: { $set: false },
        ...action.payload
      });

    case PlanActionType.USER_START_TRIAL_PLUGIN_REQUEST:
      return update(state, {
        callingStartTrialPlugin: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.USER_START_TRIAL_PLUGIN_SUCCESS:
      return update(state, {
        callingStartTrialPlugin: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.USER_START_TRIAL_PLUGIN_FAILURE:
      return update(state, {
        callingStartTrialPlugin: { $set: false },
        ...action.payload
      });

    case PlanActionType.GET_BCP_PLUGINS_SUCCESS:
      return update(state, {
        ...action.payload
      });

    case PlanActionType.GET_ASIN_TRACKER_PLAN_REQUEST:
      return update(state, {
        callingGetListPlan: { $set: true },
        errorMessage: { $set: {} }
      });

    case PlanActionType.GET_ASIN_TRACKER_PLAN_SUCCESS:
      return update(state, {
        callingGetListPlan: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case PlanActionType.GET_ASIN_TRACKER_PLAN_FAILURE:
      return update(state, {
        callingGetListPlan: { $set: false },
        ...action.payload
      });

    default:
      return state;
  }
}
