import { LicenseManager } from "@ag-grid-enterprise/core";
import ModuleApp from "./app";
import {
  SignInContainer,
  SignUpContainer,
  AuthorizeMws,
  SignUpBSContainer,
  LoginWithAmazon
} from "./auth/login";
import ResetPasswordContainer from "./auth/resetpassword/ResetPasswordContainer";

LicenseManager.setLicenseKey(
  "CompanyName=asinzen,LicensedApplication=asinzen,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-009916,ExpiryDate=19_August_2021_[v2]_MTYyOTMyNzYwMDAwMA==6c7c47bdece7f7ef3d2c870d7c822d08"
);

export {
  ModuleApp,
  SignInContainer,
  SignUpContainer,
  ResetPasswordContainer,
  AuthorizeMws,
  SignUpBSContainer,
  LoginWithAmazon
};
