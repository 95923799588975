export default Object.freeze({
  KEY_LOCAL_TOKEN: "user_az_token",
  KEY_LOCAL_STORAGE: "user_az_info",
  KEY_LOCAL_ASIN_TRACKER_MARKETPLACE: "user_az_asin_tracker_marketplace",
  KEY_LOCAL_ASIN_TRACKER_LISTING_COLUMNS: "user_az_asin_tracker_listing_columns",
  KEY_LOCAL_HAZMAT_DISCOVERY_MARKETPLACE: "user_az_hazmat_discovery_marketplace",
  KEY_LOCAL_PLANS: "user_az_plans",
  LIST_MARKETPLACE: [
    {
      label: "US",
      value: "ATVPDKIKX0DER",
      endpoint: "https://mws.amazonservices.com"
    },
    {
      label: "Canada",
      value: "A2EUQ1WTGCTBG2",
      endpoint: "https://mws.amazonservices.ca"
    },
    {
      label: "UK",
      value: "A13V1IB3VIYZZH",
      endpoint: "https://mws-eu.amazonservices.com"
    },
    {
      label: "DE",
      value: "",
      endpoint: "https://mws.amazonservices.de"
    }
  ],
  USER_KEY: "user_az_key",
  PASSWORD_KEY: "password_az_key"
});
