import React from "react";
import HelpIcon from "@material-ui/icons/HelpOutline";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import RemoveIcon from "@material-ui/icons/DeleteForever";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

export const styles = theme => {
  return {
    modalConfirmSelect: {
      width: 450,
      [theme.breakpoints.down(700)]: {
        width: "100%",
        padding: theme.spacing(1)
      }
    },
    buttonActive: {
      color: theme.palette.colors.blue
    },
    buttonUnActive: {
      color: theme.palette.colors.red
    },
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto"
    },
    btnDelete: {
      color: theme.palette.colors.red,
      cursor: "pointer"
    }
  };
};

const rows = [
  { id: "fullName", numeric: false, disablePadding: true, label: "Name" },
  { id: "email", numeric: false, disablePadding: true, label: "Email" },
  {
    id: "disabledCalculatorSettings",
    numeric: false,
    disablePadding: true,
    label: "Disable Calculator Setting",
    tabValue: "AZINSIGHT"
  },
  {
    id: "accessHistory",
    numeric: false,
    disablePadding: true,
    label: "Access History",
    tabValue: "AZINSIGHT"
  },
  {
    id: "status",
    label: "Status",
    tabValue: "AZINSIGHT",
    disablePadding: true
  },
  { id: "createdAt", numeric: false, disablePadding: true, label: "Date created" },
  { id: "", numeric: false, disablePadding: true, label: "Actions" }
];

export class ListMemberMobile extends React.Component {
  renderRecord = id => {
    const {
      record,
      editMember,
      selectMarketplace,
      handleGetUserData,
      handleDeleteMember,
      sendEmailInstructions,
      tabValue,
      classes
    } = this.props;
    let content = "";
    switch (id) {
      case "fullName":
        content = `${record.firstName} ${record.lastName}`;
        break;
      case "email":
        content = record.email;
        break;
      case "disabledCalculatorSettings":
        content =
          tabValue === "AZINSIGHT" ? (
            <FormControlLabel
              checked={record.disabledCalculatorSettings}
              control={
                <Checkbox
                  onChange={e => editMember(e, record)}
                  className={classes.checkbox}
                  value={record.disabledCalculatorSettings}
                  color="primary"
                />
              }
              label=""
            />
          ) : null;
        break;
      case "status":
        content =
          tabValue === "AZINSIGHT" ? (
            <span style={{ textTransform: "capitalize" }}>{record.status}</span>
          ) : null;
        break;
      case "createdAt":
        content = record.createdAt ? moment(record.createdAt).format("ll") : "";
        break;
      case "":
        content = (
          <>
            {tabValue === "AZINSIGHT" ? (
              <Tooltip title="Clone the Calculator Settings from the main account to this account">
                <CopyIcon
                  onClick={() => selectMarketplace(record)}
                  style={{ cursor: "pointer", marginRight: "5px" }}
                />
              </Tooltip>
            ) : null}
            <EditIcon
              onClick={handleGetUserData(record)}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
            <RemoveIcon
              onClick={handleDeleteMember(record)}
              style={{ cursor: "pointer", marginRight: "5px", color: "red" }}
              className={classes.btnDelete}
            />
            {tabValue === "AZINSIGHT" ? (
              <MailOutlineIcon
                onClick={() => sendEmailInstructions(record)}
                style={{ cursor: "pointer", marginRight: "5px" }}
              />
            ) : null}
          </>
        );
        break;
      default:
        "";
    }
    return content;
  };

  render() {
    const { tabValue, classes, index } = this.props;

    return (
      <Grid className={classes.listMemberMobileInner}>
        <strong className={classes.rowMobile} key="index">
          # {index + 1}
        </strong>
        {rows
          .filter(r => !r.tabValue || r.tabValue === tabValue)
          .map(
            row => (
              <div className={classes.rowMobile} key={row.id}>
                <strong>{row.label} </strong>
                {row.id === "disabledCalculatorSettings" || row.id === "status" ? (
                  <Tooltip
                    title={
                      row.id === "disabledCalculatorSettings"
                        ? "When you check in the box to disable calculator settings, the sub-account will inherit and mirror the calculator settings from the main account"
                        : row.id === "status"
                        ? "Sub-accounts become inactive when a sub-account plan expires or the number of sub-accounts is decreased on the plan. Once an account becomes inactive it can no longer log in."
                        : null
                    }
                    placement="right"
                    enterDelay={300}
                  >
                    <HelpIcon style={{ fontSize: 16 }} />
                  </Tooltip>
                ) : null}
                <span>: {this.renderRecord(row.id)}</span>
              </div>
            ),
            this
          )}
      </Grid>
    );
  }
}
