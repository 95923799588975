/*eslint-disable*/
import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import { FaCreditCard } from "react-icons/fa/index";
import { findIndex } from "lodash";
import { connectAutoDispatch } from "redux/reduxConnect";
import { withSnackbar } from "notistack";
import numeral from "numeral";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ModalCancelSubscription from "./ModalCancelSubscription";
import { apiReviewUpcomingInvoice } from "api";

class PlansAzinsightUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCancelReasonDialog: false,
      openUpgradeConfirmation: false,
      cancelReason: null,
      upcoming: {},
      reasons: [
        {
          id: 6,
          text: "I am just disabling the auto renew feature for my subscription"
        },
        {
          id: 1,
          text: "Found another tool",
          note: ""
        },
        {
          id: 2,
          text: "Taking a break from selling on Amazon"
        },
        {
          id: 3,
          text: "Cutting down on expenses"
        },
        {
          id: 4,
          text: "None of the above"
        }
      ]
    };
  }

  openCheckoutUrl = () => {
    const { product } = this.props;
    if (product.upgrade) {
      this.setState({
        openUpgradeConfirmation: false,
        disabled: true
      });
    } else {
      this.setState({
        disabled: true
      });
    }
    this.props.onSubscribe(product);
  };

  updateSubscription = () => {
    const { cancelReason, reasons } = this.state;
    const { userInfo, product } = this.props;
    const reason = reasons.find(item => item.id === cancelReason);
    const reasonText = reason ? `${reason.text}${reason.note ? ": " + reason.note : ""}` : "";
    this.setState({
      openCancelReasonDialog: false,
      disabled: true
    });

    this.props.onUpdateSubscription(null, {
      subId: userInfo.stripeSubId,
      cancel: !userInfo.isCanceledAccount,
      reason: reasonText,
      title: product.title
    });
  };

  openCancelReasonDialog = () => {
    this.setState({
      openCancelReasonDialog: true
    });
  };

  closeCancelReasonDialog = () => {
    this.setState({
      openCancelReasonDialog: false,
      cancelReason: null
    });
  };

  openUpgradeConfirmation = async product => {
    try {
      const resp = await apiReviewUpcomingInvoice({
        price: product.priceId
      });
      this.setState({
        openUpgradeConfirmation: true,
        upcoming: resp
      });
    } catch (e) {
      if (e.response && e.response.status === 500) {
        this.props.showError();
      } else {
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Unknow error. Please contact support.";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      }
    }
  };

  closeUpgradeConfirmation = () => {
    this.setState({
      openUpgradeConfirmation: false
    });
  };

  cancelReasonChange = value => () => {
    this.setState({ cancelReason: value });
  };

  reasonNoteChange = (e, index) => {
    const { reasons } = this.state;
    reasons[index].note = e.target.value;
    this.setState({
      reasons
    });
  };

  render() {
    const { userInfo, product, features } = this.props;
    const {
      checkoutUrl,
      openCancelReasonDialog,
      openUpgradeConfirmation,
      cancelReason,
      upcoming
    } = this.state;
    const {
      contactId,
      firstName,
      lastName,
      email,
      productType,
      nextSubscribeAt,
      isCanceledAccount,
      stripePriceKey,
      isInTrial,
      isNotStartTrial,
      isOutOfTrial
    } = userInfo;
    let onLifeCycle =
      isCanceledAccount === true &&
      moment().isBefore(nextSubscribeAt) &&
      stripePriceKey === product.key;
    const loginToStartTrial = !isInTrial && isNotStartTrial && !isOutOfTrial;
    return (
      <>
        <div className="pricing-plan">
          <div className="plan-header">{product.title}</div>
          <div className="plan-price">
            <span className="plan-price-amount">
              <span className="plan-price-currency">$</span>
              {product.amount}
            </span>
            /{product.interval}
          </div>
          <div className="plan-items">
            {features.map((feature, i) => (
              <div className="plan-item" key={i}>
                <div className="plan-item-mobile">
                  {feature.name}{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    className={feature.name.toLowerCase().replace(/\W+/g, "_")}
                    id={feature.name.toLowerCase().replace(/\W+/g, "_")}
                  >
                    <i className="fa fa-info-circle" aria-hidden="true" />
                  </span>
                </div>
                {feature.plans.includes(product.title) ? (
                  <i className="fa fa-check" style={{ color: "green" }}></i>
                ) : (
                  <i className="fa fa-times" style={{ color: "red" }}></i>
                )}
              </div>
            ))}
          </div>
          <div className="plan-footer">
            {product.subscribed ? (
              <>
                {onLifeCycle ? (
                  <button
                    className="button is-fullwidth is-info is-outlined"
                    onClick={this.updateSubscription}
                  >
                    Enable Auto Renew
                  </button>
                ) : product.mode === "subscription" ? (
                  <button
                    className="button is-fullwidth is-danger"
                    id={`cancel-sub-${stripePriceKey}`}
                    onClick={this.openCancelReasonDialog}
                  >
                    Cancel Subscription
                  </button>
                ) : (
                  <button disabled={true} className="button is-fullwidth is-info">
                    Already Subscribed
                  </button>
                )}
              </>
            ) : !isOutOfTrial && isInTrial ? (
              <button className="button is-fullwidth is-info" onClick={this.openCheckoutUrl}>
                In free trial. Subscribe now if you want
              </button>
            ) : loginToStartTrial ? (
              <a
                className="button is-fullwidth is-info is-outlined"
                target="_blank"
                href="https://chrome.google.com/webstore/detail/azinsight-amazon-fba-prod/gefiflkplklbfkcjjcbobokclopbigfg"
              >
                Log into AZInsight or ScanEZ to start the free trial
              </a>
            ) : (
              <button
                disabled={product.disabled}
                className={`button is-fullwidth is-info`}
                onClick={() =>
                  product.upgrade ? this.openUpgradeConfirmation(product) : this.openCheckoutUrl()
                }
              >
                {product.upgrade ? "Upgrade Now" : "Subscribe Now"}
              </button>
            )}
          </div>
        </div>

        <Dialog
          open={openCancelReasonDialog}
          fullWidth
          maxWidth="md"
          onClose={this.closeCancelReasonDialog}
        >
          <DialogTitle disableTypography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <strong>
                  We're sad to see you go. To improve our service, can you please help us understand
                  why you are canceling your subscription today?
                </strong>
              </Grid>
              <Grid item xs={12}>
                <strong>
                  Note: If you're canceling in order to change your plan, you'll have to wait until
                  the end of the subscription term.
                </strong>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ overflowY: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormLabel>Select a Reason</FormLabel>
                <FormControl fullWidth>
                  {this.state.reasons.map((item, index) => {
                    return (
                      <>
                        <FormControlLabel
                          onChange={this.cancelReasonChange(item.id)}
                          checked={cancelReason === item.id}
                          control={<Radio className="checkbox" value={item.id} color="primary" />}
                          label={item.text}
                          key={`item_reason_${item.id}`}
                        />
                        {item.hasOwnProperty("note") && cancelReason === item.id ? (
                          <TextField
                            fullWidth
                            onChange={e => this.reasonNoteChange(e, index)}
                            style={{ maxWidth: 440, marginLeft: 10 }}
                            label="Which tool are you changing to?"
                            type="text"
                            value={item.note}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeCancelReasonDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!cancelReason}
              onClick={this.updateSubscription}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openUpgradeConfirmation}
          fullWidth
          maxWidth="sm"
          onClose={this.openUpgradeConfirmation}
        >
          <DialogTitle>
            <strong>Are you sure you want to upgrade your plan?</strong>
          </DialogTitle>
          <DialogContent style={{ overflowY: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p>
                  <strong>
                    If you choose to upgrade, we will credit you for the balance of the unused time
                    you had remaining on your previous plan. This credit will then be used towards
                    paying for the cost of upgrading to the Pro plan.
                  </strong>
                </p>
                <div style={{ marginTop: 10 }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {upcoming.items &&
                        upcoming.items.map(item => (
                          <tr key={item.id}>
                            <td>{item.description}</td>
                            <td className="text-right">
                              {item.amount >= 0 ? (
                                <strong>${numeral(item.amount / 100).format("0,0.00")}</strong>
                              ) : (
                                <strong>
                                  -${numeral(Math.abs(item.amount) / 100).format("0,0.00")}
                                </strong>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tbody style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ padding: "16px 0" }}>
                          Total to be automatically charged to your payment method today
                        </td>
                        <td style={{ padding: "16px 0" }} className="text-right">
                          <strong>${numeral(upcoming.amount / 100).format("0,0.00")}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p style={{ marginTop: 10 }}>
                  <strong>
                    By clicking the button "Upgrade my account", you agree that asinzen will
                    automatically charge the payment method on file for the total amount owed (shown
                    above).
                  </strong>
                </p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="default" onClick={this.closeUpgradeConfirmation}>
              Go Back
            </Button>
            <Button variant="outlined" color="primary" onClick={this.openCheckoutUrl}>
              Upgrade My Account
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connectAutoDispatch(null, {})(withSnackbar(PlansAzinsightUI));
