import React, { PureComponent } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class InputSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { inputId, data, onChange, inputLabel, fullWidth, list } = this.props;
    return (
      <FormControl fullWidth={fullWidth}>
        {inputLabel && (
          <InputLabel style={{ transform: "unset", fontSize: 15 }} htmlFor={inputId}>
            {inputLabel}
          </InputLabel>
        )}
        <Select onChange={onChange} value={data}>
          {list.map(item => (
            <MenuItem key={`item_input_select_${item.value}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

InputSelect.defaultProps = {
  fullWidth: true,
  onChange: null,
  inputId: "INPUT_KEY_ID",
  inputLabel: ""
};

InputSelect.propTypes = {
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string
};

export default InputSelect;
