/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import { compose } from "redux";

import { apiGetPortalUrl } from "api";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row"
  },
  iframe: {
    border: "none",
    display: "flex",
    flex: 1,
    flexDirection: "row"
  }
});

class CustomerPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portalUrl: ""
    };
  }

  getPortalUrl = async () => {
    const resp = await apiGetPortalUrl();
    this.setState({
      portalUrl: resp.url
    });
  };

  componentDidMount() {
    this.getPortalUrl();
  }

  render() {
    const { classes } = this.props;
    const { portalUrl } = this.state;
    return (
      <div className={classes.root}>
        {portalUrl ? <iframe className={classes.iframe} src={portalUrl} /> : null}
      </div>
    );
  }
}

CustomerPortal.defaultProps = {
  userState: userInitState
};

CustomerPortal.propTypes = {
  userState: PropTypes.shape(userPropTypes)
};

export default compose(withStyles(styles))(CustomerPortal);
