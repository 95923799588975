import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import numeral from "numeral";

const PRICE_TABLES = {
  AZINSIGHT_PLUS_ADDITIONAL_LOGIN: [
    {
      from: 1,
      to: 5,
      price: 13
    },
    {
      from: 6,
      to: 10,
      price: 12
    },
    {
      from: 11,
      to: "∞",
      price: 10
    }
  ],
  AZINSIGHT_PLUS_ADDITIONAL_LOGIN_MONTHLY: [
    {
      from: 1,
      to: 5,
      price: 1.7
    },
    {
      from: 6,
      to: 10,
      price: 1.6
    },
    {
      from: 11,
      to: "∞",
      price: 1.4
    }
  ],
  AZINSIGHT_PRO_ADDITIONAL_LOGIN: [
    {
      from: 1,
      to: 5,
      price: 35
    },
    {
      from: 6,
      to: 10,
      price: 30
    },
    {
      from: 11,
      to: "∞",
      price: 25
    }
  ],
  AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY: [
    {
      from: 1,
      to: 5,
      price: 4.5
    },
    {
      from: 6,
      to: 10,
      price: 4
    },
    {
      from: 11,
      to: "∞",
      price: 3.5
    }
  ]
};

const defaultAvailablePlugins = [
  {
    _id: "annually",
    intervalLables: "Annually",
    price: 35,
    interval: "year",
    content:
      "Additional login license for AZInsight Advanced. Each additional login license will allow a user to log in using a unique email and password.",
    code: "AZINSIGHT_PRO_ADDITIONAL_LOGIN"
  },
  {
    _id: "monthly",
    intervalLables: "Monthly",
    price: 4.5,
    interval: "month",
    content:
      "Additional login license for AZInsight Advanced. Each additional login license will allow a user to log in using a unique email and password.",
    code: "AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY"
  }
];

export default function AZInsightSubAccounts({
  plugins,
  userInfo,
  data,
  pluginsAdded,
  classes,
  intervalLables,
  openZohoArticle,
  openRenewConfirmation,
  openStripePortal,
  openCheckoutUrl
}) {
  const subscribed = data.find(item => item.id === userInfo.stripePriceKey);
  let availablePlugins = plugins.map(item => {
    const pluginAdded = pluginsAdded.find(
      added => added.pluginId && added.pluginId._id === item._id
    );

    if (pluginAdded) {
      item.added = true;
      item.canceled = pluginAdded.isCancel;
      item.stripeSubId = pluginAdded.stripeSubId;
      item.nextSubscribeAt = pluginAdded.nextSubscribeAt;
    } else {
      item.added = false;
    }

    return item;
  });

  if (subscribed && subscribed.interval.toLowerCase() !== "year") {
    availablePlugins = availablePlugins.filter(
      item => item.interval.toLowerCase() === subscribed.interval.toLowerCase()
    );
  }

  return (
    <Grid container className={classes.subAccountWrapper}>
      {!subscribed ? (
        <>
          <Grid item xs={12}>
            Your plan comes with 2 sub-accounts by default. Click{" "}
            <a href="#" onClick={e => openZohoArticle(e, "640662000000629160")}>
              here
            </a>{" "}
            to learn how to manage your sub-accounts.
          </Grid>
          <Grid item xs={12}>
            If you need additional sub-accounts, you need to{" "}
            <strong>first subscribe to a plan</strong>.
          </Grid>

          <Grid item xs={12}>
            <div className="pricing-table plugin-list">
              {defaultAvailablePlugins.map(item => (
                <div key={item._id} className="pricing-plan is-active">
                  <div className="plan-header">Add Sub-accounts {item.intervalLables}</div>
                  <div className="plan-price">
                    <span className="plan-price-amount has-text-info">
                      {numeral(item.price).format("0,0.00")}/{item.interval}/sub-account
                    </span>
                  </div>
                  <div className="plan-items">
                    {item.content ? <div className="plan-item">{item.content}</div> : null}
                    <div className="plan-item">
                      <table className="table is-bordered is-hoverable is-fullwidth">
                        <thead>
                          <tr>
                            <th colSpan={4}>Tiered quantity discounts</th>
                          </tr>
                          <tr className="has-text-left">
                            <th>&nbsp;</th>
                            <th>From</th>
                            <th>To</th>
                            <th className="has-text-right">Per sub-account</th>
                          </tr>
                        </thead>
                        <tbody className="has-text-left">
                          {PRICE_TABLES[item.code].map((item, index) => (
                            <tr key={`key-${index}`}>
                              <td>{index === 0 ? "FOR THE FIRST" : "FOR THE NEXT"}</td>
                              <td>{item.from}</td>
                              <td>{item.to}</td>
                              <td className="has-text-right">
                                ${numeral(item.price).format("0,0.00")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            Current subscribed plan:{" "}
            <strong>
              {subscribed.title} / {intervalLables[subscribed.interval.toLowerCase()]}
            </strong>
          </Grid>
          <Grid item xs={12}>
            Total sub-accounts you have: <strong>{userInfo.totalPlusToken}</strong>
          </Grid>
          <Grid item xs={12}>
            Click{" "}
            <a href="#" onClick={e => openZohoArticle(e, "640662000000629160")}>
              here
            </a>{" "}
            to learn how to manage your sub-accounts.
          </Grid>
          <Grid item xs={12}>
            Your plan allows you to purchase additional sub-accounts monthly{" "}
            {subscribed.interval.toLowerCase() === "year" ? "or annually" : null}
          </Grid>

          <Grid item xs={12}>
            <div className="pricing-table plugin-list">
              {availablePlugins.map(item => (
                <div key={item._id} className="pricing-plan is-active">
                  <div className="plan-header">
                    Add Sub-accounts {intervalLables[item.interval.toLowerCase()]}
                  </div>
                  <div className="plan-price">
                    <span className="plan-price-amount has-text-info">
                      {numeral(item.price).format("0,0.00")}/{item.interval}/sub-account
                    </span>
                  </div>
                  <div className="plan-items">
                    {item.content ? <div className="plan-item">{item.content}</div> : null}
                    <div className="plan-item">
                      <table className="table is-bordered is-hoverable is-fullwidth">
                        <thead>
                          <tr>
                            <th colSpan={4}>Tiered quantity discounts</th>
                          </tr>
                          <tr className="has-text-left">
                            <th>&nbsp;</th>
                            <th>From</th>
                            <th>To</th>
                            <th className="has-text-right">Per sub-account</th>
                          </tr>
                        </thead>
                        <tbody className="has-text-left">
                          {PRICE_TABLES[item.code].map((item, index) => (
                            <tr key={`key-${index}`}>
                              <td>{index === 0 ? "FOR THE FIRST" : "FOR THE NEXT"}</td>
                              <td>{item.from}</td>
                              <td>{item.to}</td>
                              <td className="has-text-right">
                                ${numeral(item.price).format("0,0.00")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {item.added ? (
                      <>
                        {item.canceled ? (
                          <div className="plan-item">
                            Your subscription is active until{" "}
                            <strong>{moment(item.nextSubscribeAt).format("MMMM DD, YYYY")}</strong>{" "}
                            and will <strong>NOT</strong> auto-renew. If you wish to automatically
                            renew your subscription, please click{" "}
                            <a
                              href="#"
                              onClick={e =>
                                openRenewConfirmation(e, {
                                  subId: item.stripeSubId,
                                  cancel: false
                                })
                              }
                            >
                              here.
                            </a>
                          </div>
                        ) : (
                          <div className="plan-item">
                            Your subscription is active until{" "}
                            <strong>{moment(item.nextSubscribeAt).format("MMMM DD, YYYY")}</strong>{" "}
                            and will auto-renew. If you wish to cancel auto-renew on your
                            subscription, please click{" "}
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                openStripePortal();
                              }}
                            >
                              here.
                            </a>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="plan-footer">
                    {!item.added ? (
                      <button
                        className="button is-info is-fullwidth"
                        onClick={() => openCheckoutUrl(item)}
                      >
                        Purchase Sub-account Subscription
                      </button>
                    ) : (
                      <button
                        className="button is-info is-fullwidth"
                        onClick={e =>
                          item.canceled
                            ? openRenewConfirmation(e, {
                                subId: item.stripeSubId,
                                cancel: false
                              })
                            : openStripePortal()
                        }
                      >
                        {item.canceled ? "Enable Auto Renew" : "Update or Cancel"} Sub-account
                        Subscription
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
