/*eslint-disable*/
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InputText } from "components/forms";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { formatNumber } from "utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreditCard from "@material-ui/icons/CreditCard";
import DateRange from "@material-ui/icons/DateRange";
import Security from "@material-ui/icons/Security";
import IconVisa from "static/img/visa-mastercard-icon-2.jpg";
import IconMaster from "static/img/visa-mastercard-icon-3.jpg";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { connectAutoDispatch } from "redux/reduxConnect";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from "react-stripe-elements";

const Wrapper = styled.div`
  width: 450px;

  & .form-input {
    margin: 15px auto;
  }
`;

const Title = styled(DialogTitle)`
  background: #eef2f4;
  color: #5d6f78;
  border-bottom: 1px solid #dedede;
`;

const Content = styled(DialogContent)`
  padding: 12px !important;
`;

const ButtonPayment = styled.button`
  background: ${props => props.theme.palette.primary.main};
  border: 1px solid ${props => props.theme.palette.primary.main};
  width: 100%;
  padding: 15px;
  text-align: center;
  color: white;
  cursor: pointer;
  outline: none;
  position: relative;
  & .text-c {
    font-size: 20px;
    font-weight: bold;
  }
`;

const ListImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px 12px 0px 12px;
  & img{
    width: 100px;
    height: 100%;
  }
  
  & .icon-master {
    background-image: url("${IconMaster}");
    display: inline-block;
   width: 70px;
    height: 46px;
    background-repeat: no-repeat;
    background-size: 70px 70px;
    margin: 0px;
    padding: 0px;
    background-position: 0px -13px;
  }
  
  & .icon-visa {
    background-image: url("${IconVisa}");
    width: 70px;
    height: 46px;
    background-repeat: no-repeat;
    background-size: 70px 70px;
    margin: 0px;
    padding: 0px;
    background-position: 0px -13px;
  }
`;

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  buttonProgress: {
    color: "white"
  },
  title: {
    padding: theme.spacing(2)
  }
});

class ModalConfirm extends React.Component {
  state = {
    cardNumber: "",
    expiredDate: "",
    securityCode: "",
    planId: "5c4f434e636ce3e0f1443cb0",
    loading: false
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose(false);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.open && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  handleConfirm = e => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true });
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then(({ token, error }) => {
          if (error) {
            this.setState({ loading: false });
            return this.props.enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              },
              autoHideDuration: 1400
            });
          }
          this.props.onConfirm &&
            this.props.onConfirm({
              planId: this.state.planId,
              token: token.id
            });
        })
        .catch(error => console.log(error.message));
    }
  };

  render() {
    const { title, total, classes } = this.props;
    const { loading } = this.state;
    return (
      <Dialog open={this.props.open} onClose={this.handleClose} className="DDD">
        <Wrapper>
          <Title className={classes.title}>{title}</Title>
          <Content>
            <form onSubmit={this.handleConfirm}>
              <CardNumberElement placeholder="Card number XXXX - XXXX - XXXX - XXXX" />

              <CardExpiryElement placeholder="Expire MM/YY" />

              <CardCVCElement placeholder="CVC" />

              <PostalCodeElement placeholder="Postal code" />
            </form>
          </Content>
          <div>
            <ButtonPayment type="submit" onClick={this.handleConfirm}>
              {!loading && <span className="text-c">Pay ${formatNumber(total, true, true)}</span>}
              {loading && <CircularProgress className={classes.buttonProgress} size={21} />}
            </ButtonPayment>
          </div>
        </Wrapper>
      </Dialog>
    );
  }
}

ModalConfirm.defaultProps = {
  title: "",
  open: false,
  onClose: null,
  onConfirm: null,
  total: 0
};
ModalConfirm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  total: PropTypes.number
};

export default withStyles(styles)(
  connectAutoDispatch(
    ({ userState }) => ({ userInfo: userState.userInfo }),
    {}
  )(withStyles(styles)(withSnackbar(injectStripe(ModalConfirm))))
);
