import { createActions } from "redux-actions";

export const StripeActionTypes = {
  STRIPE_GET_PRICES_REQUEST: "STRIPE_GET_PRICES_REQUEST",
  STRIPE_GET_PRICES_SUCCESS: "STRIPE_GET_PRICES_SUCCESS",
  STRIPE_GET_PRICES_FAILURE: "STRIPE_GET_PRICES_FAILURE"
};

export const {
  stripeGetPricesRequest,
  stripeGetPricesFailure,
  stripeGetPricesSuccess
} = createActions({
  [StripeActionTypes.STRIPE_GET_PRICES_REQUEST]: payload => payload,
  [StripeActionTypes.STRIPE_GET_PRICES_SUCCESS]: payload => payload,
  [StripeActionTypes.STRIPE_GET_PRICES_FAILURE]: payload => payload
});
