import React, { useRef } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import constants from "utils/constants";
import { apiGetUserAuthorizeSpApiStatus, apiUserDisconnectSpApi } from "api/user";
import { connectAutoDispatch } from "redux/reduxConnect";
import ContentCopyIcon from "@material-ui/icons/FileCopy";

// import SnackBar from "components/SnackBars/SnackBars";
import { withSnackbar } from "notistack";

const styles = theme => ({
  main: {
    display: "block", // Fix IE 11 issue.
    width: "100%",
    [theme.breakpoints.up(700)]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paperHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px`
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  status: {
    fontSize: 16,
    "& > span": {
      textTransform: "capitalize"
    }
  },
  authorizeBtn: {
    marginTop: `${theme.spacing(2)}px`,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& button": {
      minWidth: 100,
      marginTop: 10
    }
  }
});

const SpApiStatusContainer = props => {
  const { classes } = props;
  const [status, setStatus] = React.useState({
    na: null,
    eu: null
  });

  const [error, setError] = React.useState(null);

  const [errorInfo, setErrorInfo] = React.useState({
    email: null,
    sellerId: null
  });

  const errorElm = useRef(null);

  const copyErrorMessage = () => {
    if (errorElm.current) {
      navigator.clipboard.writeText(errorElm.current.innerText);
      props.enqueueSnackbar("Copied to clipboard", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
    }
  };

  const authorize = regionShortName => {
    window.location.href = `${window.location.origin}/sp-auth?accessToken=${localStorage.getItem(
      constants.KEY_LOCAL_TOKEN
    )}&regionShortName=${regionShortName}&authorizeFrom=portal&state=portal`;
  };

  const getAuthorizeStatus = async () => {
    try {
      const data = await apiGetUserAuthorizeSpApiStatus();
      setStatus({ ...data });
    } catch (e) {
      console.log(e);
    }
  };

  const unAuthorize = async regionShortName => {
    try {
      const data = await apiUserDisconnectSpApi({ regionShortName });
      setStatus(status => ({
        ...status,
        ...data
      }));
    } catch (e) {
      console.log(e);
    }
  };

  // const handleShowChat = () => {
  //   window.ZohoHCAsap && window.ZohoHCAsap.Action("open");
  // };

  React.useEffect(() => {
    getAuthorizeStatus();
    const params = new URLSearchParams(window.location.search);
    if (params.get("error") === "110") {
      setError(params.get("regionShortName"));
      setErrorInfo({
        email: params.get("email"),
        sellerId: params.get("sellerId")
      });
      // setTimeout(() => setError(null), 10000);
    }
  }, []);

  const errorMessage = (
    <>
      <div ref={errorElm} className="red-c" style={{ marginTop: 10 }}>
        ERROR-110: We have detected that this SellerID:{" "}
        <strong className="red-c">{errorInfo.sellerId}</strong> is already connected to a different
        account: <strong className="red-c">{errorInfo.email}</strong>. If you forgot your password,
        click{" "}
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            window.open(
              "https://support.asinzen.com/portal/en/kb/articles/how-do-i-change-or-reset-my-password",
              "_blank"
            )
          }
        >
          here
        </span>{" "}
        to learn how to reset it. If you have any other issues please click{" "}
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => window.open("https://asinzen.com/contactus", "_blank")}
        >
          here
        </span>{" "}
        to chat with a Customer Champion.
      </div>
      <br />
      <div
        onClick={copyErrorMessage}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <ContentCopyIcon />
        &nbsp;Copy Error Message
      </div>
    </>
  );

  return (
    <main className={classes.main}>
      <Paper className={classes.paperHeader}>
        <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
          Amazon Integration Status
        </Typography>
      </Paper>
      <Paper className={classes.paper}>
        <div>
          <strong className={classes.status}>
            North America based seller account (US, Mexico, Canada){" "}
          </strong>
        </div>
        <div className={classes.authorizeBtn}>
          {status.na !== null ? (
            <>
              {status.na ? (
                <>
                  <strong className="has-text-primary connected-text">
                    Connected successfully
                  </strong>
                  <button className="button is-small is-danger" onClick={() => unAuthorize("NA")}>
                    Disconnect
                  </button>
                </>
              ) : (
                <>
                  <p className="red-c">Not connected</p>
                  <p>Click button below to connect.</p>
                  <button className="button is-small is-info" onClick={() => authorize("NA")}>
                    Connect
                  </button>
                </>
              )}
            </>
          ) : (
            "..."
          )}
          {error && error.toLowerCase() === "na" ? errorMessage : null}
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <strong className={classes.status}>
          Europe based seller account (UK, DE, FR, ES, IT){" "}
        </strong>
        <div className={classes.authorizeBtn}>
          {status.eu !== null ? (
            <>
              {status.eu ? (
                <>
                  <strong className="has-text-primary">Connected successfully</strong>{" "}
                  <button className="button is-small is-danger" onClick={() => unAuthorize("EU")}>
                    Disconnect
                  </button>
                </>
              ) : (
                <>
                  <p className="red-c">Not connected</p>
                  <p>Click button below to connect.</p>
                  <button className="button is-small is-info" onClick={() => authorize("EU")}>
                    Connect
                  </button>
                </>
              )}
            </>
          ) : (
            "..."
          )}
          {error && error.toLowerCase() === "eu" ? errorMessage : null}
        </div>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(
  connectAutoDispatch(
    state => ({ userInfo: state.userState.userInfo }),
    {}
  )(withSnackbar(SpApiStatusContainer))
);
