import { hot } from "react-hot-loader";
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  SignInContainer,
  SignUpContainer,
  ResetPasswordContainer,
  AuthorizeMws,
  SignUpBSContainer,
  LoginWithAmazon
} from "containers";
import CreditCardVerificationResult from "containers/auth/login/CreditCardVerificationResult";
import { MainLayout } from "components";
import { isEmptyObject } from "utils";
import { connectAutoDispatch } from "redux/reduxConnect";
import { getUserInfoAppRequest } from "redux/actions/UserActions";

class AuthWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.props.getUserInfoAppRequest();
  }

  state = {
    loading: false
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: true }), 800);
  }

  renderLoading() {
    return <div>loading...</div>;
  }

  renderLayoutApp(Comp, props = {}) {
    if (isEmptyObject(this.props.userInfo)) {
      return <Redirect to="/sign-in" />;
    }
    return <MainLayout Comp={Comp} {...this.props} {...props} />;
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props;
    if (prevProps.userInfo !== this.props.userInfo) {
      if (userInfo && this.props.userInfo._id && !window.ZohoHCAsapSettings.ticketsSettings) {
        window.ZohoHCAsapSettings.ticketsSettings = {
          preFillFields: {
            email: {
              defaultValue: userInfo.email
            },
            contactId: {
              defaultValue: `${userInfo.firstName} ${userInfo.lastName}`
            },
            departmentId: {
              defaultValue: "640662000000265329"
            }
          }
        };
      } else {
        window.ZohoHCAsap && window.ZohoHCAsap.Action && window.ZohoHCAsap.Action("close");
        if (window.ZohoHCAsapSettings && window.ZohoHCAsapSettings.ticketsSettings) {
          window.ZohoHCAsapSettings.ticketsSettings = null;
        }
      }
    }
  }

  render() {
    return this.state.loading ? (
      <BrowserRouter>
        <Switch>
          <Route exact path="/reset-password/:token" component={ResetPasswordContainer} />
          <Route exact path="/sign-in" component={SignInContainer} />
          <Route exact path="/sign-up" component={SignInContainer} />
          <Route exact path="/sign-up/:module" component={SignInContainer} />
          <Route exact path="/resetpassword" component={SignInContainer} />
          <Route exact path="/asin-zen-insight/sign-up" component={SignUpContainer} />
          <Route exact path="/bulkscanpro/signup" component={SignUpBSContainer} />
          <Route exact path="/authorize-mws" component={AuthorizeMws} />
          <Route exact path="/sp-auth" component={LoginWithAmazon} />
          <Route exact path="/cc-verification" component={CreditCardVerificationResult} />
          <MainLayout {...this.props} userInfo={this.props.userInfo} />
        </Switch>
      </BrowserRouter>
    ) : (
      this.renderLoading()
    );
  }
}

export default hot(module)(
  connectAutoDispatch(state => ({ userInfo: state.userState.userInfo }), { getUserInfoAppRequest })(
    AuthWrapper
  )
);
