/*eslint-disable*/
import React from "react";
import { find } from "lodash";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { connectAutoDispatch } from "redux/reduxConnect";
import {
  getProductPlanRequest,
  getCurrentPluginsRequest,
  getCurrentPluginsAddedRequest,
  userBuyPluginsRequest
} from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import { planInitState, planPropTypes } from "redux/reducers/PlanReducer";
import { withSnackbar } from "notistack";
import NewSnackbar from "components/NewSnackbar";
import PlansUI from "components/plans/PlansUI";
import PlansAzinsightUI from "components/plans/PlansAzinsightUI";
import PlanUICommon from "components/plans/asin-tracker";
import PluginsUI from "components/plans/PluginsUI";
import AlertDialogSlide from "components/AlertDialogSlide";
import { findIndex, groupBy } from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import AZInshgitIcon from "../../../static/img/azinsight.png";
import BulkCheckerIcon from "../../../static/img/bulkchecker.png";
import AZAlertIcon from "../../../static/img/azalert.png";

const styles = theme => ({
  root: {},
  appBar: {
    backgroundColor: "#fff",
    "& .MuiTab-textColorPrimary": {
      color: "#293990",
      paddingBottom: 0,
      paddingTop: 8,
      minHeight: 0
    },
    "& .MuiButtonBase-root": {
      minWidth: 250
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#293990"
    },
    "& .Mui-selected": {
      color: "#293990"
    },
    "& .MuiTab-labelIcon": {}
  },
  tab: {
    marginTop: 30,
    "&.hidden": {
      display: "none"
    }
  },
  tabIcon: {
    width: 24
  }
});

class MarketPlace extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const query = new URLSearchParams(location.search);
    this.queryPlan = query.get("plan");
    this.queryPlugin = query.get("plugin");
    let planTab = 0;
    let pluginTab = {
      azInsight: 0,
      bulkChecker: this.queryPlugin === "paid" ? 1 : 0,
      asinTracker: this.queryPlugin === "paid" ? 1 : 0
    };
    switch (this.queryPlan) {
      case "bulkchecker":
        planTab = 1;
        break;
      case "asintracker":
        planTab = 2;
        break;
      default:
        break;
    }

    this.state = {
      isOpenSnack: false,
      snackVariant: null,
      snackMessage: null,
      gPadding: "0 150px",
      gSpacing: 1,
      gXs: 6,
      gSm: 4,
      openPopup: false,
      planTab,
      pluginTab
    };
    this.props.getProductPlanRequest();
    this.props.getCurrentPluginsRequest();
    this.props.getCurrentPluginsAddedRequest();
  }
  componentDidMount() {
    // window.analytics.page("ManageAddOns");
    window.addEventListener("resize", this.handleForReponsive);
    this.handleForReponsive();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleForReponsive);
  }

  componentDidUpdate(prevProps) {
    const { planState } = this.props;
    if (
      prevProps.planState.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      !planState.errorMessage.code
    ) {
      this.props.enqueueSnackbar(
        "Thank you! Please refresh your browser for the new plugin to show up",
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          autoHideDuration: 5000
        }
      );
    }

    if (
      prevProps.planState.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      planState.errorMessage.code
    ) {
      this.props.enqueueSnackbar(planState.errorMessage.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 5000
      });
    }

    if (
      planState.callingGetListPlan !== prevProps.planState.callingGetListPlan &&
      !planState.callingGetListPlan &&
      this.queryPlugin
    ) {
      setTimeout(() => {
        const div = document.getElementById("AZ-Plugin");
        div.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }

  handleResetCss = () => {
    return this.setState({ gPadding: "0 150px", gSpacing: 40, gXs: 6, gSm: 4 });
  };

  handleForReponsive = () => {
    const w = window.innerWidth;
    if ((w <= 890 && w >= 599) || w < 599) {
      return this.setState({ gXs: 12, gSm: 6, gPadding: "0 25px", gSpacing: 1 });
    }
    if (w <= 1200 && w >= 889) {
      return this.setState({ gPadding: "0 25px", gSpacing: 1 });
    }
    if (w > 1200) {
      return this.handleResetCss();
    }
  };

  handleShowSnackBar = (snackVariant, snackMessage) => {
    this.setState({
      isOpenSnack: true,
      snackVariant,
      snackMessage
    });
  };

  renderShowSnackBar() {
    const { isOpenSnack, snackVariant, snackMessage } = this.state;
    return isOpenSnack === false ? (
      ""
    ) : (
      <NewSnackbar
        onClose={() => this.setState({ isOpenSnack: false })}
        variant={snackVariant}
        message={snackMessage}
        isOpen={isOpenSnack}
      />
    );
  }

  detectIsExtraLogin = () => {
    const {
      planState: {
        currentPlugins: { data },
        pluginsAdded
      }
    } = this.props;
    const len = pluginsAdded.length;
    for (let i = 0; i < len; i++) {
      const pluginId = pluginsAdded[i].pluginId;
      const index = findIndex(data, { _id: pluginId });
      if (index >= 0 && data[index].isExtraPlugin === true) {
        return true;
      }
    }
    return false;
  };

  renderInsightPlan() {
    const { gXs, gSm } = this.state;
    const {
      userState,
      planState: {
        currentPlans: { data }
      }
    } = this.props;
    const {
      productType,
      amount,
      plan,
      banAllAccess,
      upgradeToInsight,
      userHazType,
      isInTrial,
      isNotStartTrial,
      isOutOfTrial
    } = userState.userInfo;
    return data
      .map(d => {
        if (
          productType === "basic" &&
          amount &&
          plan &&
          banAllAccess === false &&
          ["AZInsight_PLAN"].includes(d.id)
        ) {
          if (d.id === "HAZability_PLAN") return null;
          return {
            ...d,
            disable: true
          };
        }
        if (
          productType === "hazmat" &&
          amount &&
          plan &&
          banAllAccess === false &&
          ["HAZability_PLAN"].includes(d.id)
        ) {
          return {
            ...d,
            disable: true
          };
        }
        if (
          ((productType === "hazbasic" && amount && plan && banAllAccess === false) ||
            (upgradeToInsight === true && userHazType === "HAZ_FREE_ALL")) &&
          ["HAZability_PLAN_BASIC"].includes(d.id)
        ) {
          return {
            ...d,
            disable: true
          };
        }
        return d;
      })
      .filter(d => d && d.id)
      .filter(d => {
        if (productType === "hazmat" && ["HAZability_PLAN_BASIC"].includes(d.id)) return false;
        if (
          productType === "basic" &&
          ["HAZability_PLAN", "HAZability_PLAN_BASIC"].includes(d.id)
        ) {
          return false;
        }

        return true;
      })
      .map((d, k) => (
        <PlansAzinsightUI
          isOutOfTrial={isOutOfTrial}
          isNotStartTrial={isNotStartTrial}
          isInTrial={isInTrial}
          gXs={gXs}
          gSm={gSm}
          handleShowSnackBar={this.handleShowSnackBar}
          key={k}
          currentUserData={userState.userInfo}
          detectIsExtraLogin={this.detectIsExtraLogin()}
          showAlert={this.showAlert}
          item={d}
          k={k}
        />
      ));
  }

  checkUserBuyPlan = (item, listUserPlan = []) => {
    for (let i = 0; i < listUserPlan.length; i++) {
      if (item._id === listUserPlan[i]._id) {
        return { isActive: listUserPlan[i].isActive, isCancel: listUserPlan[i].isCancel };
      }
    }
    return {};
  };

  renderBulkCheckerPlan = () => {
    const { gXs, gSm } = this.state;
    const {
      userState,
      planState: {
        bulkCheckerPlans: { data }
      }
    } = this.props;
    const { listUserPlan } = userState.userInfo;
    return data
      .map(item => {
        return { ...item, ...this.checkUserBuyPlan(item, listUserPlan) };
      })
      .map((d, k) => (
        <PlanUICommon
          gXs={gXs}
          gSm={gSm}
          handleShowSnackBar={this.handleShowSnackBar}
          key={k}
          currentUserData={userState.userInfo}
          detectIsExtraLogin={this.detectIsExtraLogin()}
          showAlert={this.showAlert}
          item={d}
          k={k}
        />
      ));
  };

  renderAsinTrackerPlan = () => {
    const { gXs } = this.state;
    const {
      userState,
      planState: {
        asinTrackerPlans: { data }
      }
    } = this.props;
    const { listUserPlan } = userState.userInfo;
    return data
      .map(item => {
        return { ...item, ...this.checkUserBuyPlan(item, listUserPlan) };
      })
      .map((d, k) => (
        <PlanUICommon
          gXs={gXs}
          gSm={3}
          handleShowSnackBar={this.handleShowSnackBar}
          key={k}
          currentUserData={userState.userInfo}
          detectIsExtraLogin={this.detectIsExtraLogin()}
          showAlert={this.showAlert}
          item={d}
          k={k}
        />
      ));
  };

  renderAzalertPlan = () => {
    const { gXs, gSm } = this.state;
    const { currentPlugins, pluginsAdded } = this.props.planState;
    const { userState } = this.props;
    const plugin = find(currentPlugins.data, { code: "AZIPALERT" });
    if (!plugin) return null;
    if (pluginsAdded) {
      let temp = pluginsAdded.filter(item => item.pluginId && item.pluginId._id === plugin._id);
      plugin.disable = temp && temp.length > 0;
    }
    return (
      <PlansUI
        gXs={gXs}
        gSm={gSm}
        handleShowSnackBar={this.handleShowSnackBar}
        key={plugin._id}
        currentUserData={userState.userInfo}
        detectIsExtraLogin={this.detectIsExtraLogin()}
        showAlert={this.showAlert}
        item={plugin}
        k={123123}
      />
    );
  };

  showAlert = () => {
    this.setState({ openPopup: true });
  };

  getPlugins = (plugins, showAZAlert) => {
    const {
      userState: { userInfo }
    } = this.props;
    if (!plugins || !plugins.length) return [];
    return plugins.filter(d => {
      if (showAZAlert) {
        if (d.code === "AZALERT_EXTRA_LOGIN") {
          return true;
        } else {
          return false;
        }
      } else if (
        (["hazmat", "basic"].includes(userInfo.productType) && d.code === "AZIPALERT") ||
        d.code === "AZALERT_EXTRA_LOGIN"
      ) {
        return false;
      }
      return true;
    });
  };

  renderShowPlugins(plans, plugins) {
    const { gXs, gSm } = this.state;
    const {
      userState: { userInfo },
      planState: { pluginsAdded }
    } = this.props;
    return (
      (plugins &&
        plugins.length > 0 &&
        plugins
          .map(d => {
            const plAD =
              (pluginsAdded &&
                pluginsAdded.length > 0 &&
                pluginsAdded.find(dd => dd.pluginId && dd.pluginId._id === d._id)) ||
              null;
            const isBought = !plAD || plAD.isBought === false ? false : true;
            const isFree = (plAD && plAD.isFree) || (d.price === 0 && !d.interval ? true : false);
            return {
              ...d,
              isBought,
              isFree,
              isCancel: (plAD && plAD.isCancel) || false,
              endCircleAt: (plAD && plAD.endCircleAt) || null,
              pluginSubscribedAt: (plAD && plAD.createdAt) || null
            };
          })
          .map((d, k) => {
            return (
              d &&
              d.code !== "AZIPALERT" && (
                <Grid item xs={gXs} sm={gSm} key={k} className="Maxwidth-450">
                  <PluginsUI
                    handleShowSnackBar={(a, b) => this.handleShowSnackBar(a, b)}
                    currentUserData={userInfo}
                    item={d}
                    userBuyPluginsRequest={this.props.userBuyPluginsRequest}
                    currentPlans={plans}
                    pluginsAdded={pluginsAdded}
                    exclude={["AZIPALERT"]}
                  />
                </Grid>
              )
            );
          })) ||
      ""
    );
  }

  renderPopup = () => {
    return (
      <AlertDialogSlide
        isHtml
        open
        isDisagree
        isAgree
        handleClose={() => {}}
        handleDisagre={() => this.setState({ openPopup: false })}
        handleAgree={() => this.setState({ openPopup: false })}
        title="Warning"
        content={`
      <div>
        <p>Please contact our <a target="_blank" rel="noreferrer noopener" href="https://support.asinzen.com/">Custom Service</a> for support. We're more than happy to walk you through the upgrade</p>
      </div>
		`}
        textAgree="Yes"
      />
    );
  };

  planTabChange = (e, planTab) => {
    this.props.getUserInfoAppRequest();
    this.setState({
      planTab
    });
  };

  pluginTabChange = (tab, key) => {
    const { pluginTab } = this.state;
    pluginTab[key] = tab;
    this.setState({
      pluginTab
    });
  };

  render() {
    const { openPopup, planTab, pluginTab } = this.state;
    const {
      classes,
      planState,
      userState: { userInfo }
    } = this.props;
    const {
      currentPlugins,
      asinTrackerPlugins,
      currentPlans,
      bulkCheckerPlans,
      bcpPlugins
    } = planState;
    let plugins = this.getPlugins(currentPlugins.data);
    let azAlertPlugins = this.getPlugins(currentPlugins.data, true);
    let freePlugins = plugins.filter(item => !item.price);
    let paidPlugins = plugins.filter(item => item.price);
    freePlugins = groupBy(freePlugins, "parent");
    paidPlugins = groupBy(paidPlugins, "parent");
    freePlugins.ASIN_TRACKER = asinTrackerPlugins.data.filter(item => !item.price);
    paidPlugins.ASIN_TRACKER = asinTrackerPlugins.data.filter(item => item.price);
    paidPlugins.BULK_CHECKER_PRO =
      (userInfo &&
        userInfo.listUserPlan &&
        findIndex(userInfo.listUserPlan, { id: "BULK_CHECKER_PRO" }) >= 0 &&
        bcpPlugins.data.filter(item => item.price)) ||
      [];
    return (
      <div className={classes.root}>
        {this.renderShowSnackBar()}
        {openPopup && this.renderPopup()}
        <Paper
          square
          classes={{
            root: classes.appBar
          }}
        >
          <Tabs value={planTab} onChange={this.planTabChange} centered>
            <Tab icon={<img className={classes.tabIcon} src={AZInshgitIcon} />} label="AZInsight" />
            <Tab
              icon={<img className={classes.tabIcon} src={BulkCheckerIcon} />}
              label="Bulk Checker PRO"
            />
            <Tab icon={<img className={classes.tabIcon} src={AZAlertIcon} />} label="AZAlert" />
          </Tabs>
        </Paper>
        <Grid
          container
          classes={{
            root: `${classes.tab} ${planTab !== 0 ? "hidden" : ""}`
          }}
          spacing={2}
        >
          {this.renderInsightPlan()}
          <Grid
            item
            xs={12}
            classes={{
              root: classes.tab
            }}
            id="AZ-Plugin"
          >
            <Tabs
              value={pluginTab.azInsight}
              onChange={(e, tab) => this.pluginTabChange(tab, "azInsight")}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={`PAID Plugins (${
                  paidPlugins.AZINSIGHT ? paidPlugins.AZINSIGHT.length - 1 : 0
                })`}
              />
            </Tabs>
            <Grid
              container
              classes={{
                root: `${classes.tab}`
              }}
              spacing={2}
            >
              {this.renderShowPlugins(currentPlans, paidPlugins.AZINSIGHT)}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          classes={{
            root: `${classes.tab} ${planTab !== 1 ? "hidden" : ""}`
          }}
          spacing={2}
        >
          {this.renderBulkCheckerPlan()}
          <Grid
            item
            xs={12}
            classes={{
              root: classes.tab
            }}
          >
            <Tabs
              value={pluginTab.bulkChecker}
              onChange={(e, tab) => this.pluginTabChange(tab, "bulkChecker")}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={`FREE Plugins (${
                  freePlugins.BULK_CHECKER_PRO ? freePlugins.BULK_CHECKER_PRO.length : 0
                })`}
              />
              <Tab
                label={`PAID Plugins (${
                  paidPlugins.BULK_CHECKER_PRO ? paidPlugins.BULK_CHECKER_PRO.length : 0
                })`}
              />
            </Tabs>
            <Grid
              container
              classes={{
                root: `${classes.tab} ${pluginTab.bulkChecker !== 0 ? "hidden" : ""}`
              }}
              spacing={2}
            >
              {this.renderShowPlugins(bulkCheckerPlans, freePlugins.BULK_CHECKER_PRO)}
            </Grid>
            <Grid
              container
              classes={{
                root: `${classes.tab} ${pluginTab.bulkChecker !== 1 ? "hidden" : ""}`
              }}
              spacing={2}
            >
              {this.renderShowPlugins(bulkCheckerPlans, paidPlugins.BULK_CHECKER_PRO)}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          classes={{
            root: `${classes.tab} ${planTab !== 2 ? "hidden" : ""}`
          }}
        >
          {this.renderAzalertPlan()}
          <Grid item xs={12}>
            <Tabs
              value={pluginTab.asinTracker}
              onChange={(e, tab) => this.pluginTabChange(tab, "asinTracker")}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={`FREE Plugins (${0})`} />
              <Tab label={`PAID Plugins (${azAlertPlugins ? azAlertPlugins.length : 0})`} />
            </Tabs>
            <Grid
              container
              classes={{
                root: `${classes.tab} ${pluginTab.asinTracker !== 0 ? "hidden" : ""}`
              }}
              spacing={2}
            >
              {this.renderShowPlugins(
                {
                  data: []
                },
                freePlugins.ASIN_TRACKER
              )}
            </Grid>
            <Grid
              container
              classes={{
                root: `${classes.tab} ${pluginTab.asinTracker !== 1 ? "hidden" : ""}`
              }}
              spacing={2}
            >
              {this.renderShowPlugins(currentPlans, azAlertPlugins)}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          classes={{
            root: `${classes.tab} ${planTab !== 3 ? "hidden" : ""}`
          }}
          spacing={2}
        >
          {this.renderAsinTrackerPlan()}
        </Grid>
      </div>
    );
  }
}

MarketPlace.defaultProps = {
  userState: userInitState,
  planState: planInitState,
  getProductPlanRequest: null,
  getCurrentPluginsRequest: null,
  getAsinTrackerPluginsRequest: null,
  getCurrentPluginsAddedRequest: null
};

MarketPlace.propTypes = {
  userState: PropTypes.shape(userPropTypes),
  planState: PropTypes.shape(planPropTypes),
  getProductPlanRequest: PropTypes.func,
  getCurrentPluginsRequest: PropTypes.func,
  getAsinTrackerPluginsRequest: PropTypes.func,
  getCurrentPluginsAddedRequest: PropTypes.func
};

const withConnect = connectAutoDispatch(
  ({ userState, planState }) => ({
    userState,
    planState
  }),
  {
    getProductPlanRequest,
    getCurrentPluginsRequest,
    getCurrentPluginsAddedRequest,
    getUserInfoAppRequest,
    userBuyPluginsRequest
  }
);

export default compose(withRouter, withConnect, withSnackbar, withStyles(styles))(MarketPlace);
