const drawerWidth = 240;

export const ListProductStyles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  root2: {
    display: "flex",
    flex: 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaperRoot: {
    [theme.breakpoints.down(800)]: {
      display: "none"
    }
  },
  drawerPaper: {
    width: drawerWidth,
    height: "unset",
    overflow: "hidden",
    position: "absolute",
    top: 98,
    bottom: 0,
    left: 24,
    border: "none"
  },
  drawerPaperMobileRoot: {
    [theme.breakpoints.up(800)]: {
      display: "none"
    }
  },
  drawerPaperMobile: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: "auto"
  },
  menuButton: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(800)]: {
      display: "none"
    },
    maxWidth: 180
  },
  content: {
    flexGrow: 1,
    "& .pricing-table": {
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        margin: "0 auto"
      },
      "&.plugin-list": {
        justifyContent: "unset",
        "&>div": {
          marginRight: 30
        },
        "& .plan-price-amount": {
          fontSize: "1.25rem !important"
        },
        "& .table thead tr:first-child th": {
          fontSize: "0.875rem"
        }
      },
      "& .pricing-plan": {
        maxWidth: 450,
        "& .plan-footer > button": {
          fontSize: 14
        }
      },
      "& .pricing-plan.is-features": {
        [theme.breakpoints.down(1440)]: {
          display: "none"
        }
      },
      "& .plan-item-mobile": {
        [theme.breakpoints.up(1440)]: {
          display: "none"
        }
      }
    }
  },
  appBar: {
    backgroundColor: "#fff",
    "& .MuiTab-textColorPrimary": {
      color: "#293990",
      paddingBottom: 0,
      paddingTop: 8,
      minHeight: 0
    },
    "& .MuiButtonBase-root": {
      minWidth: 250,
      [theme.breakpoints.down(800)]: {
        minWidth: "unset",
        fontSize: 10
      }
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#293990"
    },
    "& .Mui-selected": {
      color: "#293990"
    },
    "& .MuiTab-labelIcon": {},
    marginBottom: 24
  },
  tab: {
    marginTop: 30,
    "&.hidden": {
      display: "none"
    }
  },
  tabIcon: {
    width: 24
  },
  pluginWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  pluginItem: {
    width: 450,
    display: "flex",
    marginLeft: 10,
    marginBottom: 10,
    "&>div": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& > .MuiCardContent-root": {
        flex: 1
      }
    }
  },
  subAccountWrapper: {
    padding: "0 20px",
    "&>div": {
      marginBottom: 10
    }
  },
  btnWrapper: {
    flexDirection: "row",
    "&>button": {
      marginRight: 20
    }
  },
  dowloadWrapper: {
    padding: 10
  },
  downloadIcon: {
    display: "flex",
    flexDirection: "row",
    "& > a": {
      maxWidth: 150,
      marginRight: 10,
      marginTop: 5,
      display: "inline-flex",
      "& > img": {
        maxWidth: "100%"
      }
    }
  }
});
