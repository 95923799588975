import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { isEmptyObject } from "utils";
import { Layout, Content } from "utils/GlobalStyles";
import { Routes, MemberRoutes } from "config/Routes";
import HeaderBar from "./HeaderBar";
import { EnterpriseSupport } from "../../containers/app/user";

const MainLayout = props => {
  const {
    location: { pathname },
    history
  } = props;
  if (isEmptyObject(props.userInfo) && pathname !== "/sign-in") {
    const search = new URLSearchParams();
    search.set("returnUrl", window.location.pathname + window.location.search);
    return <Redirect to={{ pathname: "/sign-in", search: search.toString() }} />;
  }

  return (
    <Layout>
      <HeaderBar history={history} />
      <Content>
        {props.userInfo.accountType === "USER"
          ? Routes.map(route => <Route {...route} component={route.component} />)
          : MemberRoutes.map(route => <Route {...route} component={route.component} />)}
        {props.userInfo && props.userInfo.supportType === "enterprise" ? (
          <Route exact path="/enterprise-support" component={EnterpriseSupport} />
        ) : null}
      </Content>
    </Layout>
  );
};

export default withRouter(MainLayout);
