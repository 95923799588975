import { createActions } from "redux-actions";

export const PlanActionType = {
  GET_PRODUCT_PLAN_REQUEST: "GET_PRODUCT_PLAN_REQUEST",
  GET_PRODUCT_PLAN_SUCCESS: "GET_PRODUCT_PLAN_SUCCESS",
  GET_PRODUCT_PLAN_FAILURE: "GET_PRODUCT_PLAN_FAILURE",

  GET_CURRENT_PLUGINS_REQUEST: "GET_CURRENT_PLUGINS_REQUEST",
  GET_CURRENT_PLUGINS_SUCCESS: "GET_CURRENT_PLUGINS_SUCCESS",
  GET_CURRENT_PLUGINS_FAILURE: "GET_CURRENT_PLUGINS_FAILURE",

  GET_CURRENT_PLUGINS_ADDED_REQUEST: "GET_CURRENT_PLUGINS_ADDED_REQUEST",
  GET_CURRENT_PLUGINS_ADDED_SUCCESS: "GET_CURRENT_PLUGINS_ADDED_SUCCESS",
  GET_CURRENT_PLUGINS_ADDED_FAILURE: "GET_CURRENT_PLUGINS_ADDED_FAILURE",

  USER_BUY_PLUGINS_REQUEST: "USER_BUY_PLUGINS_REQUEST",
  USER_BUY_PLUGINS_SUCCESS: "USER_BUY_PLUGINS_SUCCESS",
  USER_BUY_PLUGINS_FAILURE: "USER_BUY_PLUGINS_FAILURE"
};

export const {
  getProductPlanRequest,
  getProductPlanSuccess,
  getProductPlanFailure
} = createActions({
  [PlanActionType.GET_PRODUCT_PLAN_REQUEST]: payload => payload,
  [PlanActionType.GET_PRODUCT_PLAN_SUCCESS]: payload => payload,
  [PlanActionType.GET_PRODUCT_PLAN_FAILURE]: payload => payload
});

export const {
  getCurrentPluginsRequest,
  getCurrentPluginsSuccess,
  getCurrentPluginsFailure
} = createActions({
  [PlanActionType.GET_CURRENT_PLUGINS_REQUEST]: payload => payload,
  [PlanActionType.GET_CURRENT_PLUGINS_SUCCESS]: payload => payload,
  [PlanActionType.GET_CURRENT_PLUGINS_FAILURE]: payload => payload
});

export const {
  getCurrentPluginsAddedRequest,
  getCurrentPluginsAddedSuccess,
  getCurrentPluginsAddedFailure
} = createActions({
  [PlanActionType.GET_CURRENT_PLUGINS_ADDED_REQUEST]: payload => payload,
  [PlanActionType.GET_CURRENT_PLUGINS_ADDED_SUCCESS]: payload => payload,
  [PlanActionType.GET_CURRENT_PLUGINS_ADDED_FAILURE]: payload => payload
});

export const {
  userBuyPluginsRequest,
  userBuyPluginsSuccess,
  userBuyPluginsFailure
} = createActions({
  [PlanActionType.USER_BUY_PLUGINS_REQUEST]: payload => payload,
  [PlanActionType.USER_BUY_PLUGINS_SUCCESS]: payload => payload,
  [PlanActionType.USER_BUY_PLUGINS_FAILURE]: payload => payload
});
