/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import SnackBar from "components/SnackBars/SnackBars";
import { userCancelSubscriptionPlanRequest } from "redux/actions/UserActions";
import { getProductPlanRequest } from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import { connectAutoDispatch } from "redux/reduxConnect";
import { withSnackbar } from "notistack";

class ModalCancelSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      reportMissing: props.open,
      message: null,
      reasonCancel: 0
    };
  }

  closeReportMissing = () => {
    this.setState(
      {
        reportMissing: false
      },
      () => this.props.onClose && this.props.onClose()
    );
  };

  submitReportMissing = async () => {
    const { item } = this.props;
    const { reasonCancel } = this.state;
    this.reporting = true;
    this.props.userCancelSubscriptionPlanRequest({ id: item._id, reason: reasonCancel });
  };

  reasons = [
    {
      id: 1,
      text: "Found another tool"
    },
    {
      id: 2,
      text: "Taking a break from selling on Amazon"
    },
    {
      id: 3,
      text: "Cutting down on expenses"
    },
    {
      id: 4,
      text: "None of the above"
    }
  ];

  _handleChangeOptionCancel = reasonCancel => () => {
    this.setState({ reasonCancel: reasonCancel });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      return this.setState({
        reportMissing: this.props.open,
        report: this.props.report
      });
    }

    if (
      prevProps.callingCancelSubscriptionPlan &&
      this.props.callingCancelSubscriptionPlan !== prevProps.callingCancelSubscriptionPlan &&
      this.reporting
    ) {
      this.reporting = false;
      this.props.enqueueSnackbar("Cancel subscription successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
      this.props.getProductPlanRequest();
      this.props.getUserInfoAppRequest();
      this.closeReportMissing();
    }
  }

  render() {
    const { reportMissing, message, reasonCancel } = this.state;
    return (
      <>
        <Dialog open={reportMissing} fullWidth maxWidth="md" onClose={this.closeReportMissing}>
          <DialogTitle disableTypography>
            <strong>
              We're sad to see you go. To improve our service, can you please help us understand why
              you are canceling your subscription today?
            </strong>
          </DialogTitle>
          <DialogContent style={{ overflowY: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormLabel>Select a Reason</FormLabel>
                <FormControl fullWidth>
                  {this.reasons.map(item => {
                    return (
                      <FormControlLabel
                        onChange={this._handleChangeOptionCancel(item.id)}
                        checked={reasonCancel === item.id}
                        control={<Radio className="checkbox" value={item.id} color="primary" />}
                        label={item.text}
                        key={`item_reason_${item.id}`}
                      />
                    );
                  })}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeReportMissing}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.reporting || reasonCancel <= 0}
              onClick={this.submitReportMissing}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <SnackBar message={message} />
      </>
    );
  }
}

ModalCancelSubscription.defaultProps = {
  open: false,
  item: {},
  onClose: null
};

ModalCancelSubscription.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.shape({}),
  onClose: PropTypes.func
};

export default connectAutoDispatch(
  ({ userState }) => ({
    callingCancelSubscriptionPlan: userState.callingCancelSubscriptionPlan,
    errorMessage: userState.errorMessage
  }),
  { userCancelSubscriptionPlanRequest, getProductPlanRequest, getUserInfoAppRequest }
)(withSnackbar(ModalCancelSubscription));
