import axios from "axios";
import constants from "utils/constants";

const instance = axios.create({
  baseURL: process.env.AZ_API_URL_NEW || "http://0.0.0.0:5000/extension/",
  headers: {
    "Content-Type": "application/json"
  }
});

instance.interceptors.request.use(async config => {
  const Authorization = await localStorage.getItem(constants.KEY_LOCAL_TOKEN);
  if (Authorization) {
    config.headers["portal-token"] = Authorization;
  }
  return config;
});

export default instance;
