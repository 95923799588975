import { combineReducers } from "redux";
import user from "./UserReducer";
import plan from "./PlanReducer";
import stripeReducer from "./StripeReducer";

export default combineReducers({
  userState: user,
  planState: plan,
  stripeState: stripeReducer
});
