// import ProcessError from "api/user/ProcessError";
import axios from "utils/axios";

const baseURL = `${process.env.AZ_API_URL_NEW}/stripe`;

export const apiGetPrices = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      baseURL,
      url: `/prices`
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const apiGetPortalUrl = async ({ returnUrl }) => {
  try {
    const { data } = await axios({
      method: "POST",
      baseURL,
      url: `/portal-url`,
      data: { returnUrl }
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const apiGetCheckoutUrl = async ({ price, quantity, mode, returnUrl }) => {
  try {
    const { data } = await axios({
      method: "POST",
      baseURL,
      url: `/checkout-url`,
      data: { price, quantity, mode, returnUrl }
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const apiReviewUpcomingInvoice = async ({ price }) => {
  try {
    const { data } = await axios({
      method: "POST",
      baseURL,
      url: `/upcoming`,
      data: { price }
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const apiUpdateSubscription = async ({ subId, ...payload }) => {
  try {
    const { data } = await axios({
      method: "POST",
      baseURL,
      url: `/update-subscription`,
      data: { subId, ...payload }
    });
    return data;
  } catch (e) {
    throw e;
  }
};
