import React from "react";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledCard = styled(Card)`
  margin-bottom: ${props => props.theme.spacing(3)}px;
`;

class CustomCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StyledCard>
        <CardHeader
          title={this.props.title}
          subheader={this.props.subheader}
          action={this.props.action}
        />
        <Divider />
        <CardContent>{this.props.children}</CardContent>
      </StyledCard>
    );
  }
}

CustomCard.defaultProps = {
  title: "",
  subheader: "",
  action: null
};

CustomCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  action: PropTypes.shape({})
};

export default CustomCard;
