import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import MoreIcon from "@material-ui/icons/MoreVert";
import { NavLink, withRouter } from "react-router-dom";
import constants from "utils/constants";
import Logo from "static/img/asinzenmainlogo.png";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userPropTypes, userInitState } from "redux/reducers/UserReducer";
import { apiGetPortalUrl } from "api";

const styles = theme => {
  return {
    root: { width: "100%", display: "flex", flexDirection: "column" },
    grow: { flexGrow: 1 },
    menuButton: { marginLeft: 20, marginRight: 20, padding: 0 },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: { display: "inline-block" },
      color: theme.palette.colorLogoSecondary
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": { backgroundColor: fade(theme.palette.common.white, 0.25) },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: { marginLeft: theme.spacing(3), width: "auto" }
    },
    searchIcon: {
      width: theme.spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: { color: "inherit", width: "100%" },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(10),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: { width: 200 }
    },
    mainMenu: {
      flex: 1,
      marginLeft: 80
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: { display: "flex", alignItems: "center" }
    },
    rightFullName: {
      fontSize: "1.2em",
      fontWeight: "bold",
      cursor: "pointer"
    },
    rightUsername: {
      cursor: "pointer"
    },
    sectionMobile: { display: "flex", [theme.breakpoints.up("md")]: { display: "none" } },
    iconMenuDropDown: { fontSize: "20px", marginRight: "3px" },
    iconLink: {
      display: "inline-flex !important",
      "text-decoration": "none",
      color: "inherit",
      margin: "0px 15px"
    },
    headerLogo: { justifyContent: "flex-start" },
    ToolBar: { justifyContent: "space-between" },
    link: {
      textDecoration: "none",
      color: "rgba(0,0,0,.87)"
    },
    helper: {
      margin: "0px 8px",
      display: "inline-block",
      color: "#ccc",
      cursor: "pointer",
      "& svg": {
        verticalAlign: "middle"
      }
    },
    feedback: {
      color: "#fff"
    },
    tabs: {
      fontSize: 16,
      marginLeft: 64,
      verticalAlign: "middle",
      cursor: "pointer"
    },
    tabHighlight: {
      fontSize: 16,
      marginLeft: 64,
      verticalAlign: "middle",
      cursor: "pointer",
      fontWeight: "bold"
    },
    topMenu: {
      display: "flex",
      alignItems: "center"
    }
  };
};

class HeaderBar extends React.Component {
  state = {
    anchorEl: null,
    anchorElTracker: null,
    helpEl: null,
    anchorElProducts: null,
    anchorElSellers: null,
    tabIndex: 1
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuTrackClose = () => {
    this.setState({ anchorElTracker: null });
  };

  handleOpenTracker = event => {
    this.setState({ anchorElTracker: event.currentTarget });
  };

  handleProductsMenuOpen = event => {
    this.setState({ anchorElProducts: event.currentTarget });
  };

  handleProductsMenuClose = () => {
    this.setState({ anchorElProducts: null });
  };

  handleSellersMenuOpen = event => {
    this.setState({ anchorElSellers: event.currentTarget });
  };

  handleSellersMenuClose = () => {
    this.setState({ anchorElSellers: null });
  };

  gotoLink = link => {
    this.handleMenuClose();
    this.handleMenuTrackClose();
    this.props.history.push(link);
  };

  openUrl = url => {
    window.open(url);
    this.handleHelpMenuClose();
  };

  renderMenuDropDown = () => {
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { userInfo } = this.props;
    const links = [
      {
        link: "/plans",
        text: "Pricing Plans",
        show: userInfo.accountType === "USER"
      },
      {
        link: "/asin-zen-insight/account-management",
        text: "Sub-Accounts",
        show: userInfo.registerFrom !== "PROMOTER" && userInfo.accountType === "USER"
      },
      {
        link: "/user/amazon-integration",
        text: "Amazon Integration",
        show: userInfo.registerFrom !== "PROMOTER" && userInfo.accountType === "USER"
      },
      {
        onClick: this.openStripePortal,
        text: "Billing",
        show: userInfo.accountType === "USER"
      }
    ];
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {links.map(({ link, text, show, onClick }, index) =>
          show ? (
            <MenuItem key={index} onClick={() => (link ? this.gotoLink(link) : onClick())}>
              {text}
            </MenuItem>
          ) : null
        )}
        {userInfo.accountType === "USER" && (
          <MenuItem onClick={this.handleChangePassword}>Profile Settings</MenuItem>
        )}
        <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
      </Menu>
    );
  };

  renderMenuTrackerDropDown = () => {
    const { anchorElTracker } = this.state;
    const isMenuOpen = Boolean(anchorElTracker);
    const links = [
      {
        link: "/asin-tracker/dashboard",
        text: "Dashboard",
        show: true
      },
      {
        link: "/asin-tracker/track-asin",
        text: "Tracked Asins",
        show: true
      },
      {
        link: "/asin-tracker/report",
        text: "Reports",
        show: true
      }
    ];
    return (
      <Menu
        anchorEl={anchorElTracker}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={this.handleMenuTrackClose}
      >
        {links.map(({ link, text, show }, index) =>
          show ? (
            <MenuItem key={index} onClick={() => this.gotoLink(link)}>
              {text}
            </MenuItem>
          ) : null
        )}
      </Menu>
    );
  };

  renderProductsMenuDropDown = () => {
    const { anchorElProducts } = this.state;
    const isMenuOpen = Boolean(anchorElProducts);
    const links = [
      {
        link: "/",
        text: "Product Search",
        show: true
      },
      {
        link: "/az-api/product-viewer",
        text: "Product Viewer",
        show: true
      }
    ];
    return (
      <Menu
        anchorEl={anchorElProducts}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={this.handleProductsMenuClose}
      >
        {links.map(({ link, text, show }, index) =>
          show ? (
            <MenuItem key={index} onClick={() => this.gotoLink(link)}>
              {text}
            </MenuItem>
          ) : null
        )}
      </Menu>
    );
  };

  renderSellersMenuDropDown = () => {
    const { anchorElSellers } = this.state;
    const isMenuOpen = Boolean(anchorElSellers);
    const links = [
      {
        link: "/az-api/seller-storefront",
        text: "Seller Storefront",
        show: true
      },
      {
        link: "/az-api/top-rated-sellers",
        text: "Top Rated Sellers",
        show: true
      }
    ];
    return (
      <Menu
        anchorEl={anchorElSellers}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={this.handleSellersMenuClose}
      >
        {links.map(({ link, text, show }, index) =>
          show ? (
            <MenuItem key={index} onClick={() => this.gotoLink(link)}>
              {text}
            </MenuItem>
          ) : null
        )}
      </Menu>
    );
  };

  handleChangePassword = () => {
    this.props.history.push("/user/change-password");
  };

  handleSignOut = () => {
    localStorage.removeItem(constants.KEY_LOCAL_TOKEN);
    localStorage.removeItem(constants.KEY_LOCAL_STORAGE);
    this.props.history.push("/sign-in");
  };

  handleTeamHistoryOpen = () => {
    this.props.history.push("/user/team-history");
  };

  handlePricingPlansOpen = () => {
    this.props.history.push("/");
  };

  openBeacon = () => {
    window.ZohoHCAsap && window.ZohoHCAsap.Action("open");
    this.handleHelpMenuClose();
  };

  detectHighlightTab = path => {
    switch (path) {
      case "/":
        this.setState({ tabIndex: 1 });
        break;
      case "/user/team-history":
        this.setState({ tabIndex: 2 });
        break;
      default:
        this.setState({ tabIndex: 0 });
    }
  };

  componentDidUpdate(prevProps) {
    const { userInfo, location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      if (userInfo.accountType !== "USER") {
        this.detectHighlightTab("/user/team-history");
        return;
      }
      this.detectHighlightTab(location.pathname);
    }
  }

  componentDidMount() {
    const { userInfo, location } = this.props;
    if (userInfo.accountType !== "USER") {
      this.detectHighlightTab("/user/team-history");
      return;
    }
    this.detectHighlightTab(location.pathname);
    // window.Beacon("close");
    // window.Beacon("init", "aea3762b-1c0f-4985-9a41-fd779bed1e03");
  }

  // feedback = () => {
  //   window.Userback && window.Userback.open();
  //   this.handleHelpMenuClose();
  // };

  handleHelpMenuOpen = event => {
    this.setState({ helpEl: event.currentTarget });
  };

  handleHelpMenuClose = () => {
    this.setState({ helpEl: null });
  };

  openStripePortal = async () => {
    const resp = await apiGetPortalUrl({
      returnUrl: window.location.href
    });
    if (resp.url) {
      window.location.href = resp.url;
    }
  };

  render() {
    const { classes, userInfo } = this.props;
    const {
      anchorEl,
      helpEl,
      tabIndex
      // anchorElProducts,
      // anchorElSellers
    } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    // const isProductsMenuOpen = Boolean(anchorElProducts);
    // const isSellersMenuOpen = Boolean(anchorElSellers);
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar className={classes.ToolBar}>
            <div className={classes.topMenu}>
              <span className={classes.headerLogo}>
                <NavLink exact to="/">
                  <img width="135" src={Logo} style={{ marginRight: "15px" }} />
                </NavLink>
              </span>
              {/* <span
                aria-owns={isProductsMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProductsMenuOpen}
                color="inherit"
                className={classes.tabs}
              >
                Products
              </span>
              <span
                aria-owns={isSellersMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleSellersMenuOpen}
                color="inherit"
                className={classes.tabs}
              >
                Sellers
              </span> */}
              {userInfo.accountType === "USER" && (
                <span
                  onClick={this.handlePricingPlansOpen}
                  color="inherit"
                  className={tabIndex === 1 ? classes.tabHighlight : classes.tabs}
                >
                  Pricing Plans
                </span>
              )}
              <span
                // aria-owns={isProductsMenuOpen ? "material-appbar" : undefined}
                // aria-haspopup="true"
                onClick={this.handleTeamHistoryOpen}
                color="inherit"
                className={tabIndex === 2 ? classes.tabHighlight : classes.tabs}
              >
                History
              </span>
            </div>
            <div className={`${classes.sectionDesktop} ${classes.mainMenu} NavMenu`}>
              {userInfo.registerFrom !== "PROMOTER" && (
                <>
                  {/* {userInfo && userInfo.enableAsinTracker && (
                    <span className={classes.iconLink} onClick={this.handleOpenTracker}>
                      <span className={classes.rightFullName}>Asin Tracker</span>
                    </span>
                  )} */}
                  {/*{userInfo &&*/}
                  {/*  ["hoangtrucit@gmail.com", "asinzenteam@asinzen.com"].includes(*/}
                  {/*    userInfo.email*/}
                  {/*  ) && (*/}
                  {/*    <NavLink className={classes.iconLink} to="/list-rule-track">*/}
                  {/*      <span className={classes.rightFullName}>Notification Rules</span>*/}
                  {/*    </NavLink>*/}
                  {/*  )}*/}
                  {/* {process.env.APP_ENV === "staging" && (
                    <NavLink className={classes.iconLink} to="/azscraper/view-data">
                      <span className={classes.rightFullName}>AZscraper</span>
                    </NavLink>
                  )} */}
                  {/* {userInfo.stripeCustomerId ? (
                    <NavLink className={classes.iconLink} to="/plans">
                      <span className={classes.rightFullName}>Plans</span>
                    </NavLink>
                  ) : null}
                  {userInfo.stripeCustomerId ? (
                    <a className={classes.iconLink} onClick={this.openStripePortal}>
                      <span className={classes.rightFullName}>Billing</span>
                    </a>
                  ) : null} */}
                </>
              )}
            </div>
            <div className={`${classes.sectionDesktop} Help-Support`}>
              {/* <span>
                <Button
                  className={classes.feedback}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.feedback}
                >
                  Provide Feedback
                </Button>
              </span> */}
              <span className={classes.helper} onClick={this.handleHelpMenuOpen}>
                <HelpIcon />
              </span>
              <span
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                className={classes.rightUsername}
              >
                {userInfo.firstName} {userInfo.lastName}
              </span>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleProfileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <Menu
            anchorEl={helpEl}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(helpEl)}
            onClose={this.handleHelpMenuClose}
          >
            <MenuItem onClick={this.openBeacon}>Chat with us</MenuItem>
            <MenuItem onClick={() => this.openUrl("https://support.asinzen.com")}>
              Support Portal
            </MenuItem>
            {userInfo.supportType === "enterprise" ? (
              <MenuItem onClick={() => this.gotoLink("/enterprise-support")}>
                Enterprise Support
              </MenuItem>
            ) : null}
            <MenuItem onClick={() => this.openUrl("https://learn.asinzen.com")}>
              Learning portal
            </MenuItem>
            <MenuItem
              onClick={() => this.openUrl("https://help.asinzen.com/asinzenyoutubechannel")}
            >
              Youtube Channel
            </MenuItem>
            {/* <MenuItem onClick={this.feedback}>Leave Feedback</MenuItem> */}
          </Menu>
        </AppBar>
        {this.renderMenuDropDown()}
        {this.renderMenuTrackerDropDown()}
        {/* {this.renderProductsMenuDropDown()}
        {this.renderSellersMenuDropDown()} */}
      </div>
    );
  }
}

HeaderBar.defaultProps = {
  userInfo: userInitState.userInfo,
  classes: {}
};

HeaderBar.propTypes = {
  classes: PropTypes.shape({}),
  userInfo: userPropTypes.userInfo
};

export default withRouter(
  withStyles(styles)(
    connectAutoDispatch(
      ({ userState }) => ({
        userInfo: userState.userInfo
      }),
      {}
    )(HeaderBar)
  )
);
