import React from "react";
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer"
  }
});

const CreditCardVerificationResult = ({ classes }) => {
  const handleClose = () => {
    window.close();
  };
  return (
    <Container className={classes.container}>
      <div className="notification is-primary">
        Your credit card has beed verified. Please{" "}
        <strong onClick={handleClose} className={classes.link}>
          close
        </strong>{" "}
        the window
      </div>
    </Container>
  );
};

export default withStyles(styles)(CreditCardVerificationResult);
