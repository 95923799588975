import update from "immutability-helper";
import { StripeActionTypes } from "redux/actions/StripeActions";

export const initialStripeState = {
  prices: {
    data: [],
    loaded: false
  }
};

export default function createReducer(state = initialStripeState, action) {
  const { type, payload } = action;
  switch (type) {
    case StripeActionTypes.STRIPE_GET_PRICES_REQUEST:
      return update(state, {
        prices: {
          loaded: {
            $set: false
          },
          data: {
            $set: []
          }
        }
      });
    case StripeActionTypes.STRIPE_GET_PRICES_SUCCESS:
      return update(state, {
        prices: {
          loaded: {
            $set: true
          },
          data: {
            $set: payload
          }
        }
      });
    case StripeActionTypes.STRIPE_GET_PRICES_FAILURE:
      return update(state, {
        prices: {
          loaded: {
            $set: true
          },
          data: {
            $set: []
          }
        }
      });
    default:
      return state;
  }
}
