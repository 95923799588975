import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';

export const SnackBarStl = styled(Snackbar)`
  && {
    z-index: 99999;
  }

  && > div {
    background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
    border-radius: 3px;
    border: 0;
    color: white;
    height: 48px;
    box-shadow: 0 8px 10px 7px rgba(255, 105, 135, 0.3);
  }
`;

export const SnackBarWarning = styled(Snackbar)`
  && {
    z-index: 99999;
  }

  && > div {
    background: linear-gradient(#ffc107ad, #fcf8e3 30%, #fcf8e3 90%);
    border-radius: 3px;
    border: 0;
    color: #7b3131;
    height: 48px;
    box-shadow: 0 8px 10px 7px #ffc107ad;
  }
`;
