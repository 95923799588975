import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  StripeActionTypes,
  stripeGetPricesSuccess,
  stripeGetPricesFailure
} from "redux/actions/StripeActions";

import { apiGetPrices } from "api";

function* getPrices() {
  try {
    const prices = yield call(apiGetPrices);
    yield put(stripeGetPricesSuccess(prices));
  } catch (e) {
    yield put(stripeGetPricesFailure(e));
  }
}

function* watcherProductSaga() {
  yield takeLatest(StripeActionTypes.STRIPE_GET_PRICES_REQUEST, getPrices);
}

export default [fork(watcherProductSaga)];
