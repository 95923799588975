import React, { PureComponent } from "react";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: ${props => props.theme.spacing(3)}px;
`;

export default class SettingsCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StyledCard>
        <CardHeader
          title={this.props.title}
          subheader={this.props.subheader}
          action={this.props.action}
        />
        <Divider />
        <CardContent>{this.props.children}</CardContent>
      </StyledCard>
    );
  }
}
