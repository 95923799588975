/*eslint-disable*/
import { call, fork, put, takeLatest, all } from "redux-saga/effects";
import constants from "utils/constants";
import {
  apiUserAddMember,
  apiUserEditMember,
  apiUserGetListMember,
  apiUserSignin,
  apiUserActiveMember,
  apiGetUserInfoApp,
  apiUserResetPassword,
  apiUserDeleteMember,
  apiUserForgotPassword,
  apiChangePassword,
  apiChangeEmail,
  apiCopyCalculatorSettings,
  apiSendInstructions,
  apiAccessHistoryMember,
  apiRecordSourcingHistoryMember
} from "api";
import {
  UserActionType,
  userAddNewMemberFailure,
  userAddNewMemberSuccess,
  userEditMemberFailure,
  userEditMemberSuccess,
  userGetListMemberFailure,
  userGetListMemberSuccess,
  userGetListMemberRequest,
  userLoginFailure,
  userLoginSuccess,
  userActiveMemberSuccess,
  userActiveMemberFailure,
  getUserInfoAppRequest,
  getUserInfoAppSuccess,
  getUserInfoAppFailure,
  userResetPasswordSuccess,
  userResetPasswordFailure,
  userDeleteMemberSuccess,
  userDeleteMemberFailure,
  userForgotPasswordSuccess,
  userForgotPasswordFailure,
  userChangePasswordSuccess,
  userChangePasswordFailure,
  userChangeEmailSuccess,
  userChangeEmailFailure,
  userCopyCalculatorSettingsSuccess,
  userCopyCalculatorSettingsFailure,
  userSendInstructionsSuccess,
  userSendInstructionsFailure,
  userAccessHistoryFailure,
  userAccessHistorySuccess,
  userRecordSourcingHistorySuccess,
  userRecordSourcingHistoryFailure
} from "redux/actions/UserActions";

function* sagaUserLoginRequest(action) {
  try {
    const result = yield call(apiUserSignin, action.payload);
    localStorage.setItem(constants.KEY_LOCAL_PLANS, result.stripeSubId);
    yield put(userLoginSuccess({ userInfo: { $set: result }, info: action.payload }));
  } catch (error) {
    yield put(userLoginFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaUserGetListMember(action) {
  try {
    const result = yield call(apiUserGetListMember, action.payload);
    yield put(userGetListMemberSuccess({ listMember: { $set: result } }));
  } catch (error) {
    yield put(userGetListMemberFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaUserAddNewMember(action) {
  try {
    yield call(apiUserAddMember, action.payload);
    yield put(userAddNewMemberSuccess());
  } catch (error) {
    yield put(userAddNewMemberFailure({ errorAddMember: { $set: error.message } }));
  }
}

function* sagaUserEditMember(action) {
  try {
    yield call(apiUserEditMember, action.payload);
    yield put(userEditMemberSuccess());
  } catch (error) {
    yield put(userEditMemberFailure({ errorAddMember: { $set: error.message } }));
  }
}

function* sagaAccessHistoryMember(action) {
  try {
    yield call(apiAccessHistoryMember, action.payload);
    yield put(userAccessHistorySuccess());
  } catch (error) {
    yield put(userAccessHistoryFailure({ errorAddMember: { $set: error.message } }));
  }
}

function* sagaRecordSourcingHistoryMember(action) {
  try {
    yield call(apiRecordSourcingHistoryMember, action.payload);
    yield put(userRecordSourcingHistorySuccess());
  } catch (error) {
    yield put(userRecordSourcingHistoryFailure({ errorAddMember: { $set: error.message } }));
  }
}

function* sagaUserActiveMember(action) {
  try {
    yield call(apiUserActiveMember, action.payload);
    yield put(userGetListMemberRequest());
    yield put(userActiveMemberSuccess());
  } catch (error) {
    yield put(userActiveMemberFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaGetUserInfoApp() {
  try {
    const result = yield call(apiGetUserInfoApp);
    const listUserPlan = result.listUserPlan.map(item => {
      item.planId && delete item.planId.isActive;
      return {
        ...item.planId,
        isCancel: !!item.isCancel,
        isActive: !!item.isActive
      };
    });
    yield put(getUserInfoAppSuccess({ userInfo: { $set: { ...result, listUserPlan } } }));
  } catch (error) {
    console.error(error);
    yield put(
      getUserInfoAppFailure({
        errorMessage: { $set: error.message },
        userInfo: { $set: {} }
      })
    );
  }
}

function* sagaUserResetPassword(action) {
  try {
    yield call(apiUserResetPassword, action.payload);
    yield put(userResetPasswordSuccess());
  } catch (error) {
    yield put(
      userResetPasswordFailure({
        errorMessage: { $set: error.message }
      })
    );
  }
}

function* sagaUserDeleteMember(action) {
  try {
    yield call(apiUserDeleteMember, action.payload);
    yield put(userGetListMemberRequest());
    yield put(userDeleteMemberSuccess());
  } catch (error) {
    yield put(userDeleteMemberFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaUserForgotPassword(action) {
  try {
    yield call(apiUserForgotPassword, action.payload);
    yield put(userForgotPasswordSuccess());
  } catch (error) {
    yield put(userForgotPasswordFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaUserChangePassword(action) {
  try {
    yield call(apiChangePassword, action.payload);
    yield put(userChangePasswordSuccess());
  } catch (error) {
    yield put(
      userChangePasswordFailure({
        errorMessage: { $set: error.message }
      })
    );
  }
}

function* sagaUserChangeEmail(action) {
  try {
    yield call(apiChangeEmail, action.payload);
    yield put(getUserInfoAppRequest());
    yield put(userChangeEmailSuccess());
  } catch (error) {
    yield put(
      userChangeEmailFailure({
        errorMessage: { $set: error.message }
      })
    );
  }
}

function* sagaCopyCalculatorSettings(action) {
  try {
    const { subId, marketplaces } = action.payload;
    const apis = marketplaces.map(marketplace =>
      call(apiCopyCalculatorSettings, {
        subId,
        marketplace
      })
    );
    yield all(apis);
    yield put(userCopyCalculatorSettingsSuccess());
  } catch (error) {
    yield put(userCopyCalculatorSettingsFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaSendInstructions(action) {
  try {
    yield call(apiSendInstructions, action.payload);
    yield put(userSendInstructionsSuccess());
  } catch (error) {
    yield put(userSendInstructionsFailure({ errorMessage: { $set: error.message } }));
  }
}

function* userRequestLogin() {
  yield takeLatest(UserActionType.USER_LOGIN_REQUEST, sagaUserLoginRequest);
  yield takeLatest(UserActionType.USER_GET_LIST_MEMBER_REQUEST, sagaUserGetListMember);
  yield takeLatest(UserActionType.USER_ADD_NEW_MEMBER_REQUEST, sagaUserAddNewMember);
  yield takeLatest(UserActionType.USER_EDIT_MEMBER_REQUEST, sagaUserEditMember);
  yield takeLatest(UserActionType.USER_ACCESS_HISTORY_REQUEST, sagaAccessHistoryMember);
  yield takeLatest(
    UserActionType.USER_RECORD_SOURCING_HISTORY_REQUEST,
    sagaRecordSourcingHistoryMember
  );
  yield takeLatest(UserActionType.USER_ACTIVE_MEMBER_REQUEST, sagaUserActiveMember);
  yield takeLatest(UserActionType.GET_USER_INFO_APP_REQUEST, sagaGetUserInfoApp);
  yield takeLatest(UserActionType.USER_RESET_PASSWORD_REQUEST, sagaUserResetPassword);
  yield takeLatest(UserActionType.USER_DELETE_MEMBER_REQUEST, sagaUserDeleteMember);
  yield takeLatest(UserActionType.USER_FORGOT_PASSWORD_REQUEST, sagaUserForgotPassword);
  yield takeLatest(UserActionType.USER_CHANGE_PASSWORD_REQUEST, sagaUserChangePassword);
  yield takeLatest(UserActionType.USER_CHANGE_EMAIL_REQUEST, sagaUserChangeEmail);
  yield takeLatest(
    UserActionType.USER_COPY_CALCULATOR_SETTINGS_REQUEST,
    sagaCopyCalculatorSettings
  );
  yield takeLatest(UserActionType.USER_SEND_INSTRUCTIONS_REQUEST, sagaSendInstructions);
}

export default [fork(userRequestLogin)];
