import React from "react";
import { Grid } from "@material-ui/core";
import numeral from "numeral";

const defaultPlugins = [
  {
    _id: "additional_login_licenses",
    title: "AZAlert - Additional Login Licenses",
    price: 79.95,
    content:
      "Additional login license for AZAlert. Each login license will allow a user to log in using a unique email and password."
  }
];

export default function AZAlertSubAccounts({
  plan,
  plugins,
  userInfo,
  classes,
  openZohoArticle,
  openCheckoutUrl
}) {
  if (!plan || !plugins || !plugins.length) {
    return null;
  }
  return (
    <Grid className={classes.subAccountWrapper}>
      {!plan.subscribed ? (
        <>
          <Grid item>You need to purchase to a plan in order to add more sub-accounts</Grid>
          <Grid item>
            <div className="pricing-table plugin-list">
              {defaultPlugins.map(item => (
                <div key={item._id} className="pricing-plan is-active">
                  <div className="plan-header">{item.title}</div>
                  <div className="plan-price">
                    <span className="plan-price-amount has-text-info">
                      <span className="plan-price-currency">$</span>
                      {numeral(item.price).format("0,0.00")}
                    </span>
                    / Lifetime
                  </div>
                  <div className="plan-items">
                    {item.content ? <div className="plan-item">{item.content}</div> : null}
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            Current subscribed plan: <strong>{plan.title}</strong>
          </Grid>
          <Grid item xs={12}>
            Total sub-accounts you have: <strong>{userInfo.totalPlusTokenAzalert || 0}</strong>
          </Grid>
          <Grid item xs={12}>
            Click{" "}
            <a href="#" onClick={e => openZohoArticle(e, "640662000000629160")}>
              here
            </a>{" "}
            to learn how to manage your sub-accounts.
          </Grid>
          <Grid item>
            <div className="pricing-table plugin-list">
              {plugins.map(item => (
                <div key={item._id} className="pricing-plan is-active">
                  <div className="plan-header">{item.title}</div>
                  <div className="plan-price">
                    <span className="plan-price-amount has-text-info">
                      <span className="plan-price-currency">$</span>
                      {numeral(item.price).format("0,0.00")}
                    </span>
                    / Lifetime
                  </div>
                  <div className="plan-items">
                    {item.content ? <div className="plan-item">{item.content}</div> : null}
                  </div>
                  <div className="plan-footer">
                    <button
                      className="button is-info is-fullwidth"
                      onClick={() => openCheckoutUrl(item)}
                    >
                      Purchase
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
