import React from "react";
import { withStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/HelpOutline";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

const toolbarStyles = theme => ({
  root: {
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.primary,
    flex: "0 0 auto"
  },
  title: {
    flex: "0 0 auto",
    [theme.breakpoints.down(900)]: {
      flex: "unset"
    },
    [theme.breakpoints.down(700)]: {
      flex: "unset",
      "& .MuiTypography-root, & span": {
        fontSize: "1rem",
        lineHeight: 1.2,
        marginTop: 12,
        marginBottom: 12
      }
    }
  },
  buttonPatter: {
    backgroundColor: theme.palette.colorLogoPrimary,
    color: "white",
    "&:hover": {
      color: theme.palette.colorLogoPrimary
    },
    [theme.breakpoints.down(700)]: {
      marginBottom: 12,
      fontSize: "0.75rem"
    }
  }
});

export const styles = theme => {
  return {
    modalConfirmSelect: {
      width: 450,
      [theme.breakpoints.down(700)]: {
        width: "100%",
        padding: theme.spacing(1)
      }
    },
    buttonActive: {
      color: theme.palette.colors.blue
    },
    buttonUnActive: {
      color: theme.palette.colors.red
    },
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto",
      [theme.breakpoints.down(1100)]: {
        display: "none"
      }
    },
    btnDelete: {
      color: theme.palette.colors.red,
      cursor: "pointer"
    },
    listMemberMobileContainer: {
      [theme.breakpoints.up(1100)]: {
        display: "none"
      },
      [theme.breakpoints.down(1100)]: {
        width: "100%",
        padding: theme.spacing(3)
      }
    },
    listMemberMobileInner: {
      borderTop: "1px solid #000",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    rowMobile: {
      display: "flex",
      alignItems: "center",
      "& >span": {
        wordBreak: "break-word",
        marginLeft: 2
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: 12
    }
  };
};

const TableColtrolBar = props => {
  const { classes, onAddNewMember, userInfo, extension, fieldTotal } = props;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Chart of <span style={{ textTransform: "capitalize" }}>{extension}</span>'s Accounts{" "}
          {userInfo && userInfo[fieldTotal] && (
            <span style={{ fontSize: "17px" }}>
              (You can add up to {userInfo[fieldTotal]} active sub accounts). Learn more{" "}
              <a
                href="https://help.asinzen.com/azinsightsubaccounts"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          )}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Button
          onClick={() => onAddNewMember(extension)}
          variant="contained"
          className={classes.buttonPatter}
        >
          <AddIcon /> ADD SUB-ACCOUNT
        </Button>
      </div>
    </Toolbar>
  );
};

export const TableToolBar = withStyles(toolbarStyles)(TableColtrolBar);

const rows = [
  { id: "fullName", numeric: false, disablePadding: true, label: "Name" },
  { id: "email", numeric: false, disablePadding: true, label: "Email" },
  {
    id: "disabledCalculatorSettings",
    numeric: false,
    disablePadding: true,
    label: "Disable Calculator Setting",
    tabValue: "AZINSIGHT"
  },
  {
    id: "accessHistory",
    numeric: false,
    disablePadding: true,
    label: "Access History",
    tabValue: "AZINSIGHT"
  },
  {
    id: "recordSourcingHistory",
    numeric: false,
    disablePadding: true,
    label: "Record Sourcing History",
    tabValue: "AZINSIGHT"
  },
  {
    id: "status",
    label: "Status",
    tabValue: "AZINSIGHT",
    disablePadding: true
  },
  { id: "createdAt", numeric: false, disablePadding: true, label: "Date created" },
  { id: "", numeric: false, disablePadding: true, label: "" }
];

export class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, tabValue } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">#</TableCell>
          {rows
            .filter(r => !r.tabValue || r.tabValue === tabValue)
            .map(
              row => (
                <TableCell
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title={
                      row.id === "disabledCalculatorSettings"
                        ? "When you check in the box to disable calculator settings, the sub-account will inherit and mirror the calculator settings from the main account"
                        : row.id === "status"
                        ? "Sub-accounts become inactive when a sub-account plan expires or the number of sub-accounts is decreased on the plan. Once an account becomes inactive it can no longer log in."
                        : row.id === "accessHistory"
                        ? "Allow your team member to access the web portal history"
                        : row.id === "recordSourcingHistory"
                        ? "Records your team member sourcing history"
                        : "Sort"
                    }
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                      &nbsp;
                      {row.id === "disabledCalculatorSettings" ||
                      row.id === "status" ||
                      row.id === "accessHistory" ||
                      row.id === "recordSourcingHistory" ? (
                        <HelpIcon style={{ fontSize: 16 }} />
                      ) : null}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ),
              this
            )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};
