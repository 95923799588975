import React from "react";
import { Grid } from "@material-ui/core";

import PluginsUI from "components/plans/PluginItem";

export default function ShowPlugins({
  plans,
  plugins,
  userInfo,
  pluginsAdded,
  prices,
  classes,
  userBuyPluginsRequest,
  handleShowSnackBar
}) {
  return (
    <Grid className={classes.pluginWrapper}>
      {plugins && plugins.length
        ? plugins.map((d, k) => {
            const plAD =
              (pluginsAdded &&
                pluginsAdded.length > 0 &&
                pluginsAdded.find(dd => dd.pluginId._id === d._id)) ||
              null;
            const isBought = !plAD || plAD.isBought === false ? false : true;
            const isFree = (plAD && plAD.isFree) || (d.price === 0 && !d.interval ? true : false);
            let data = null;
            const price = prices.data.find(item => item.metadata.key === d.code);
            if (price) {
              data = {
                ...d,
                isBought,
                isFree,
                isCancel: (plAD && plAD.isCancel) || false,
                endCircleAt: (plAD && plAD.endCircleAt) || null,
                pluginSubscribedAt: (plAD && plAD.createdAt) || null,
                priceId: price.id,
                key: price.metadata.key,
                mode: price.type === "recurring" ? "subscription" : "payment",
                stripeSubId: plAD ? plAD.stripeSubId : null
              };
            }

            return data ? (
              <Grid item key={k} className={classes.pluginItem}>
                <PluginsUI
                  handleShowSnackBar={(a, b) => handleShowSnackBar(a, b)}
                  currentUserData={userInfo}
                  item={data}
                  userBuyPluginsRequest={userBuyPluginsRequest}
                  currentPlans={plans}
                  pluginsAdded={pluginsAdded}
                />
              </Grid>
            ) : null;
          })
        : null}
    </Grid>
  );
}
