import React from "react";
import PropTypes from "prop-types";
import { connectAutoDispatch } from "redux/reduxConnect";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { userAddNewMemberRequest, userSendInstructionsRequest } from "redux/actions/UserActions";
import { withSnackbar } from "notistack";
import { ModalConfirm } from "components/forms";
import shallowEqual from "utils/shallowEqual";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
  inner: {
    [theme.breakpoints.down(1000)]: {
      maxWidth: "50%",
      flexBasic: "unset"
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: "80%",
      flexBasic: "unset"
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: "100%",
      padding: theme.spacing(2),
      flexBasic: "unset"
    }
  },
  inputField: {
    margin: "8px 0px"
  },
  buttonCancel: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none"
    }
  },
  buttonSave: {
    backgroundColor: theme.palette.colorLogoPrimary,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.colorLogoPrimary,
      color: "white"
    }
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

class AddNewMemberContainer extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match || {};
    const extension = params && params.extension ? params.extension.toUpperCase() : "AZINSIGHT";
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repassword: "",
      openConfirm: false,
      sendInstructionsConfirm: false,
      instructionsData: null,
      open: false,
      errorMessage: "",
      messagePassword: "",
      isClose: false,
      disabledCalculatorSettings: extension === "AZINSIGHT",
      extension,
      showPassword: false,
      showRepassword: false
    };
  }

  handleChangeInput = key => e => {
    this.setState({ [key]: e.target.value }, () => {
      if (key === "repassword" || key === "password") this.comparePassword();
    });
  };

  handleSubmit = isClose => () => {
    if (this.props.callingAddNewMember) return;
    this.setState({
      openConfirm: false,
      isClose
    });
    const {
      firstName,
      lastName,
      email,
      password,
      repassword,
      extension,
      disabledCalculatorSettings
    } = this.state;
    this.props.userAddNewMemberRequest({
      firstName,
      lastName,
      email,
      password,
      repassword,
      disabledCalculatorSettings: extension === "AZINSIGHT" ? disabledCalculatorSettings : false,
      extension
    });
  };

  handleCancel = () => {
    this.props.history.push(`/asin-zen-insight/account-management?tab=${this.state.extension}`);
  };

  handleOpenConfirm = () => {
    this.setState(prevState => ({ openConfirm: !prevState.openConfirm }));
  };

  handleSubmitSendInstructions = () => {
    const { instructionsData } = this.state;
    this.props.userSendInstructionsRequest({
      ...instructionsData
    });
    this.handleCloseSendInstructionsConfirm();
  };

  handleCloseSendInstructionsConfirm = () => {
    this.setState({
      sendInstructionsConfirm: false,
      instructionsData: null
    });
  };

  componentDidMount() {
    // window.analytics.page("AddNewMember");
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      this.props.addNewMemberSuccess &&
      this.props.addNewMemberSuccess !== prevProps.addNewMemberSuccess
    ) {
      this.props.enqueueSnackbar("New member saved", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
      const { email, password, extension } = this.state;
      if (extension === "AZINSIGHT") {
        this.setState({
          sendInstructionsConfirm: true,
          instructionsData: {
            email,
            password
          }
        });
      } else {
        this.state.isClose
          ? setTimeout(this.handleCancel, 1400)
          : this.setState({
              email: "",
              firstName: "",
              lastName: "",
              password: "",
              repassword: ""
            });
      }
    }

    if (
      this.props.sendInstructionsSuccess &&
      this.props.sendInstructionsSuccess !== prevProps.sendInstructionsSuccess
    ) {
      this.props.enqueueSnackbar("Send instructions to team member successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 2000
      });
    }

    if (
      prevStates.sendInstructionsConfirm !== this.state.sendInstructionsConfirm &&
      !this.state.sendInstructionsConfirm
    ) {
      setTimeout(this.handleCancel, 1400);
    }

    const { errorAddMember } = this.props;
    if (!shallowEqual(prevProps.errorAddMember, this.props.errorAddMember)) {
      if (["EXCEPTION"].indexOf(errorAddMember.code) > -1) {
        this.props.enqueueSnackbar(
          "Has error when add new member, contact with administrator please",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            autoHideDuration: 9000
          }
        );
      }

      if (["LIMIT"].indexOf(errorAddMember.code) > -1) {
        this.props.enqueueSnackbar(
          <div dangerouslySetInnerHTML={{ __html: errorAddMember.message.message }} />,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            autoHideDuration: 5000
          }
        );
      }

      if (["OUTOFTRIAL"].indexOf(errorAddMember.code) > -1) {
        this.setState({
          open: true,
          errorMessage: errorAddMember.message.message
        });
        setTimeout(this.handleClose, 6000);
      }
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
      errorMessage: ""
    });
  };

  comparePassword = () => {
    const { password, repassword } = this.state;
    if (password && repassword) {
      if (password !== repassword) {
        this.setState({
          messagePassword: "Password and repassword don't match"
        });
      } else {
        this.setState({ messagePassword: "" });
      }
    }
  };

  handleCheckboxChange = (e, key) => {
    const { checked } = e.target;
    this.setState({
      [key]: checked
    });
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  toggleRepassword = () => {
    const { showRepassword } = this.state;
    this.setState({
      showRepassword: !showRepassword
    });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      repassword,
      disabledCalculatorSettings,
      open,
      errorMessage,
      messagePassword,
      extension
    } = this.state;
    const {
      classes,
      errorAddMember: { message }
    } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={4} className={classes.inner}>
          <Card>
            <CardContent>
              <Typography component="h2" variant="h5">
                Add a sub-account
              </Typography>

              <TextField
                error={!!message.firstName}
                helperText={message.firstName || ""}
                className={classes.inputField}
                fullWidth
                label="First name"
                value={firstName}
                onChange={this.handleChangeInput("firstName")}
              />

              <TextField
                error={!!message.lastName}
                helperText={message.lastName || ""}
                className={classes.inputField}
                fullWidth
                label="Last name"
                value={lastName}
                onChange={this.handleChangeInput("lastName")}
              />

              <TextField
                error={!!message.email}
                helperText={
                  message.email ? <div dangerouslySetInnerHTML={{ __html: message.email }} /> : ""
                }
                className={classes.inputField}
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={this.handleChangeInput("email")}
              />

              <TextField
                error={!!message.password || !!messagePassword}
                helperText={message.password || messagePassword}
                className={classes.inputField}
                fullWidth
                label="Password"
                type={this.state.showPassword ? "text" : "password"}
                value={password}
                onChange={this.handleChangeInput("password")}
                onBlur={this.comparePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.togglePassword}>
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                error={!!message.repassword || !!messagePassword}
                helperText={message.repassword || messagePassword}
                className={classes.inputField}
                fullWidth
                label="Confirm password"
                type={this.state.showRepassword ? "text" : "password"}
                value={repassword}
                onChange={this.handleChangeInput("repassword")}
                onBlur={this.comparePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.toggleRepassword}>
                        {this.state.showRepassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {extension === "AZINSIGHT" ? (
                <FormControlLabel
                  checked={disabledCalculatorSettings}
                  control={
                    <Checkbox
                      onChange={e => this.handleCheckboxChange(e, "disabledCalculatorSettings")}
                      className="checkbox"
                      value={disabledCalculatorSettings}
                      color="primary"
                    />
                  }
                  label="Disable calculator settings menu for this sub-account"
                />
              ) : null}
            </CardContent>
            <CardActions style={{ justifyContent: "flex-end" }}>
              {extension !== "AZINSIGHT" ? (
                <Button
                  onClick={this.handleSubmit(false)}
                  variant="contained"
                  className={classes.buttonSave}
                >
                  Save & Add another account
                </Button>
              ) : null}
              <Button
                onClick={this.handleCancel}
                variant="contained"
                className={classes.buttonCancel}
              >
                Close
              </Button>
              <Button
                onClick={this.handleSubmit(true)}
                variant="contained"
                className={classes.buttonSave}
              >
                Save & Close
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <ModalConfirm
          open={this.state.openConfirm}
          onClose={this.handleOpenConfirm}
          onConfirm={this.handleSubmit}
          title="Confirm add new member"
          Content={() => <div>Do you want to create new member ?</div>}
        />

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={this.handleClose}
          autoHideDuration={6000}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
                <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </span>
            }
          />
        </Snackbar>
        <ModalConfirm
          btnSaveTitle="Send"
          btnCancelTitle="No"
          open={this.state.sendInstructionsConfirm}
          title="Send instructions to team member"
          onClose={this.handleCloseSendInstructionsConfirm}
          onConfirm={this.handleSubmitSendInstructions}
          Content={() => (
            <div>
              Would you want to email this team member the email and password to log in with
              Instructions on how to install the extension
            </div>
          )}
        />
      </Grid>
    );
  }
}

AddNewMemberContainer.defaultProps = {
  callingAddNewMember: false,
  addNewMemberSuccess: false,
  errorAddMember: {
    code: "",
    message: {}
  },
  userAddNewMemberRequest: null
};

AddNewMemberContainer.propTypes = {
  callingAddNewMember: PropTypes.bool,
  addNewMemberSuccess: PropTypes.bool,
  errorAddMember: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.shape({})
  }),
  userAddNewMemberRequest: PropTypes.func
};

export default connectAutoDispatch(
  ({ userState }) => ({
    callingAddNewMember: userState.callingAddNewMember,
    addNewMemberSuccess: userState.addNewMemberSuccess,
    callingSendInstructions: userState.callingSendInstructions,
    sendInstructionsSuccess: userState.sendInstructionsSuccess,
    errorAddMember: userState.errorAddMember
  }),
  { userAddNewMemberRequest, userSendInstructionsRequest }
)(withStyles(styles)(withSnackbar(AddNewMemberContainer)));
