import React from "react";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userChangeEmailRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import isEmail from "validator/lib/isEmail";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

const signInStyle = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  paperEmail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px`
  },
  avatar: {},
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(3),
    background: theme.palette.colorLogoPrimary,
    color: "white",
    "&:hover": {
      color: "black"
    }
  },
  label: {
    "&:focus": {
      color: "white"
    }
  },
  inputField: {
    margin: "8px 0px"
  },
  focus: {}
});

class ChangeEmailContainer extends React.Component {
  state = {
    email: "",
    errorMessage: {},
    messageEmail: ""
  };

  constructor(props) {
    super(props);
  }

  submitChangeEmail = () => {
    if (this.props.userState.callingChangeEmail) return;
    if (!isEmail(this.state.email)) {
      return this.setState({ messageEmail: "Email invalid" });
    }
    this.setState({ messageEmail: "" });
    this.props.userChangeEmailRequest({
      email: this.state.email
    });
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage.code) {
      return (
        <Grid container spacing={24} justify="center" style={{ color: "red", padding: "15px 0px" }}>
          {errorMessage.message}
        </Grid>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userState.callingChangeEmail && !this.props.userState.callingChangeEmail) {
      if (this.props.userState.errorMessage.code) {
        this.props.enqueueSnackbar(this.props.userState.errorMessage.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      } else {
        this.props.enqueueSnackbar("Change email successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      }
    }
  }

  handleChangeInput = key => e => {
    this.setState({ [key]: e.target.value }, () => {
      if (key === "repassword" || key === "password") this.comparePassword();
    });
  };

  render() {
    const { email, messageEmail } = this.state;
    const { classes, callingChangeEmail } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography variant="title" style={{ width: "100%", textAlign: "left" }}>
            Update your email
          </Typography>
          {this.renderErrorMessage()}
          <form className={classes.form}>
            <TextField
              error={!!messageEmail}
              helperText={messageEmail}
              className={classes.inputField}
              fullWidth
              label="New email address"
              type="text"
              value={email}
              onChange={this.handleChangeInput("email")}
            />
            <Button
              type="button"
              onClick={this.submitChangeEmail}
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={callingChangeEmail}
            >
              Save
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

ChangeEmailContainer.defaultProps = {
  userState: userInitState,
  userChangeEmailRequest
};

ChangeEmailContainer.propTypes = {
  userChangeEmailRequest: PropTypes.func,
  userState: PropTypes.shape(userPropTypes)
};

export default connectAutoDispatch(state => ({ userState: state.userState }), {
  userChangeEmailRequest
})(withStyles(signInStyle)(withSnackbar(ChangeEmailContainer)));
