/*--eslint-disable*/
import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  apiGetListPlan,
  apiGetCurrentPlugins,
  apiGetCurrentPluginsAdded,
  apiUserBuyPlugins
} from "api";

import {
  getProductPlanSuccess,
  getProductPlanFailure,
  getCurrentPluginsSuccess,
  getCurrentPluginsFailure,
  getCurrentPluginsAddedSuccess,
  getCurrentPluginsAddedFailure,
  getCurrentPluginsAddedRequest,
  userBuyPluginsSuccess,
  userBuyPluginsFailure,
  PlanActionType
} from "redux/actions/PlanActions";

function* sagaGetListPlan(action) {
  try {
    const result = yield call(apiGetListPlan, action.payload);
    yield put(getProductPlanSuccess({ currentPlans: { $set: { data: result } } }));
  } catch (error) {
    yield put(getProductPlanFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaUserBuyPlugins(action) {
  try {
    yield call(apiUserBuyPlugins, action.payload);
    yield put(getCurrentPluginsAddedRequest());
    yield put(userBuyPluginsSuccess());
  } catch (error) {
    yield put(userBuyPluginsFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaGetCurrentPlugins() {
  try {
    const result = yield call(apiGetCurrentPlugins);
    yield put(getCurrentPluginsSuccess({ currentPlugins: { $set: { data: result } } }));
  } catch (error) {
    yield put(getCurrentPluginsFailure({ errorMessage: { $set: error.message } }));
  }
}

function* sagaGetCurrentPluginsAdded() {
  try {
    const result = yield call(apiGetCurrentPluginsAdded);
    yield put(getCurrentPluginsAddedSuccess({ pluginsAdded: { $set: result } }));
  } catch (error) {
    yield put(getCurrentPluginsAddedFailure({ errorMessage: { $set: error.message } }));
  }
}

function* watcherPlanSaga() {
  yield takeLatest(PlanActionType.GET_PRODUCT_PLAN_REQUEST, sagaGetListPlan);
  yield takeLatest(PlanActionType.GET_CURRENT_PLUGINS_REQUEST, sagaGetCurrentPlugins);
  yield takeLatest(PlanActionType.GET_CURRENT_PLUGINS_ADDED_REQUEST, sagaGetCurrentPluginsAdded);
  yield takeLatest(PlanActionType.USER_BUY_PLUGINS_REQUEST, sagaUserBuyPlugins);
}

export default [fork(watcherPlanSaga)];
