import React, { useRef } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import Checkbox from "@material-ui/core/Checkbox";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import "react-phone-input-2/lib/material.css";

import ContentCopyIcon from "@material-ui/icons/FileCopy";
import { withSnackbar } from "notistack";

import constants from "utils/constants";
import AlertDialogSlide from "components/AlertDialogSlide";
import apiCaller from "utils/axios";
import { connectAutoDispatch } from "redux/reduxConnect";
import { UserStyles } from "./User.styled";

//https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.e21a9843-b0fc-4be9-a998-412b5ceebdcf&version=beta

const { AZ_API_URL_NEW, SP_APP_ID, SP_BETA_VERSION, SP_REDIRECT_URL } = process.env;

const REGIONS = [
  {
    shortName: "NA",
    name: "North American",
    authUrl: "https://sellercentral.amazon.com/apps/authorize/consent",
    title: "North America based seller account (US, Mexico, Canada)"
  },
  {
    shortName: "EU",
    name: "Europe",
    authUrl: "https://sellercentral-europe.amazon.com/apps/authorize/consent",
    title: "Europe based seller account (UK, DE, FR, ES, IT)"
  }
];

const CC_VERIFICATION_ERROR = {
  credit_card_is_used:
    "Error 120: It looks like the credit card you attempted to use has already been registered in our system under another account. Trying to extend your trial? Click the chat button above to talk to a customer champion.",
  unknow_error: "Opps...Something went wrong. Please try again later"
};

const LoginWithAmazon = ({ classes, enqueueSnackbar }) => {
  const [state, setState] = React.useState({
    loading: true,
    error: null,
    user: null,
    accessToken: null,
    step: 0,
    screen: null,
    limitedConfirmation: false,
    confirmationChecked: false,
    confirmationCheckedError: false,
    steps: [],
    trialType: "",
    authorizedRegions: [],
    authorized: true,
    authorizeFrom: null,
    errorMessage: {},
    waiting: false,
    verification: "",
    authorizeSpError: null,
    errorEmail: "",
    errorSellerId: ""
  });

  const errorElm = useRef(null);

  const getAuthParams = () => {
    const searchParams = new URLSearchParams();
    searchParams.set("application_id", SP_APP_ID);
    if (SP_BETA_VERSION) {
      searchParams.set("version", "beta");
    }
    return searchParams.toString();
  };

  const checkAuthorization = regions => {
    let authorized = true;
    for (let i = 0; i < REGIONS.length; i++) {
      if (!regions.includes(REGIONS[i].shortName)) {
        authorized = false;
        break;
      }
    }
    return authorized;
  };

  const authorizeUser = async (accessToken, registerFrom) => {
    try {
      const [user, authorizedRegions] = await Promise.all([
        axios
          .get(`${AZ_API_URL_NEW}/authorize-sp/users`, {
            headers: {
              Authorization: accessToken
            }
          })
          .then(resp => resp.data),
        axios
          .get(`${AZ_API_URL_NEW}/authorize-sp/regions`, {
            headers: {
              Authorization: accessToken
            }
          })
          .then(resp => resp.data)
      ]);

      setState({
        ...state,
        user,
        authorizedRegions,
        authorized: checkAuthorization(authorizedRegions),
        accessToken,
        registerFrom,
        steps: [
          "Your Account Details were recieved",
          "Integrate your Amazon Seller account",
          registerFrom === "AZINSIGHT"
            ? "Verify you have the AZInsight extension installed on the Google Chrome browser"
            : "Login into the Bulk Scan Pro desktop app or web app"
        ]
      });
    } catch (e) {
      setState({
        ...state,
        error: e.code ? e.code : e
      });
    }
  };

  const authorizeSeller = async data => {
    const response = await axios
      .post(`${AZ_API_URL_NEW}/authorize-sp/sellers`, data, {
        headers: {
          Authorization: state.accessToken
        }
      })
      .then(resp => resp)
      .catch(e => e.response);

    if (response.status !== 200) {
      const newState = { ...state, step: 1, screen: 1, loading: false };
      if (response.data && response.data.code === "ALREADY_USED") {
        newState.authorizeSpError = data.regionShortName;
        newState.errorEmail = response.data.email;
        newState.sellerId = response.data.sellerId;
      }
      setState(newState);
    } else {
      const authorizedRegions = [...state.authorizedRegions, data.regionShortName];
      const authorized = checkAuthorization(authorizedRegions);
      setState({
        ...state,
        step: authorized ? 2 : 1,
        authorizedRegions,
        authorized,
        screen: 1,
        loading: false
      });
    }
  };

  const authorizeSellerCentral = ({ url, regionShortName }) => {
    window.localStorage.setItem("regionShortName", regionShortName);
    window.location.href = url;
  };

  const handleAuthorizationFromPortal = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const authParams = getAuthParams();
      if (params.get("amazon_state")) {
        const regionShortName = window.localStorage.getItem("regionShortName");
        const region = REGIONS.find(item => regionShortName === item.shortName);
        if (region) {
          window.location.href = `${region.authUrl}?${authParams}&redirect_uri=${SP_REDIRECT_URL}`;
        }
      } else if (params.get("spapi_oauth_code") && params.get("selling_partner_id")) {
        const regionShortName = window.localStorage.getItem("regionShortName");
        const region = REGIONS.find(item => regionShortName === item.shortName);
        if (region) {
          try {
            await axios.post(
              `${AZ_API_URL_NEW}/authorize-sp/sellers`,
              {
                code: params.get("spapi_oauth_code"),
                sellingPartnerId: params.get("selling_partner_id"),
                redirectUri: SP_REDIRECT_URL,
                regionShortName
              },
              {
                headers: {
                  Authorization: window.localStorage.getItem("accessToken")
                }
              }
            );
          } catch (e) {
            if (e.response && e.response.data && e.response.data.code === "ALREADY_USED") {
              window.location.href = `${window.location.origin}/user/amazon-integration?error=110&regionShortName=${regionShortName}&sellerId=${e.response.data.sellerId}&email=${e.response.data.email}`;
              return;
            }
          }
        }
        window.location.href = `${window.location.origin}/user/amazon-integration`;
      } else {
        const region = REGIONS.find(item => item.shortName === params.get("regionShortName"));
        if (region) {
          window.localStorage.setItem("regionShortName", region.shortName);
          window.location.href = `${region.authUrl}?${authParams}&state=portal`;
        }
      }
    } catch (e) {
      setState({
        ...state,
        error: e.code ? e.code : e
      });
    }
  };

  const handleRenewAuthorization = () => {
    window.location.href = "https://asinzen.co/amzrenewintegration";
  };

  const copyErrorMessage = () => {
    if (errorElm.current) {
      navigator.clipboard.writeText(errorElm.current.innerText);
      enqueueSnackbar("Copied to clipboard", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
    }
  };

  const handleSendVerificationCode = async () => {
    try {
      setState({ ...state, errorMessage: {}, waiting: true });
      const { data } = await apiCaller.post(`${AZ_API_URL_NEW}/authorize-sp/verify-credit-card`, {
        returnUrl: window.location.href
      });
      window.location.href = data.url;
    } catch (err) {
      setState({ ...state, errorMessage: err?.response?.data, waiting: false });
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("accessToken") || window.localStorage.getItem("accessToken");
    const registerFrom = params.get("registerFrom") || window.localStorage.getItem("registerFrom");

    const authorizeFrom = params.get("state");

    if (registerFrom) {
      window.localStorage.setItem("registerFrom", registerFrom);
    }

    if (accessToken) {
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem(constants.KEY_LOCAL_TOKEN, accessToken);
    }

    if (authorizeFrom === "portal") {
      handleAuthorizationFromPortal();
    } else if (authorizeFrom === "signup") {
      if (!accessToken) {
        setState({
          ...state,
          error: "UNAUTHORIED"
        });
      } else {
        authorizeUser(accessToken, registerFrom);
      }
    } else {
      handleRenewAuthorization();
    }
  }, []);

  React.useEffect(() => {
    if (state.user) {
      const params = new URLSearchParams(window.location.search);
      const regionShortName = window.localStorage.getItem("regionShortName");
      const verification = params.get("verification");
      if (params.get("amazon_state")) {
        const region = REGIONS.find(item => regionShortName === item.shortName);
        const authParams = getAuthParams();
        window.location.href = `${region.authUrl}?${authParams}&redirect_uri=${SP_REDIRECT_URL}`;
      } else if (params.get("spapi_oauth_code") && params.get("selling_partner_id")) {
        setState({
          ...state,
          screen: 1,
          step: 1
        });
        authorizeSeller({
          code: params.get("spapi_oauth_code"),
          sellingPartnerId: params.get("selling_partner_id"),
          redirectUri: SP_REDIRECT_URL,
          regionShortName
        });
      } else if (verification) {
        if (verification === "success" && state.user.verificationStatus === "success") {
          setState({
            ...state,
            step: 2,
            screen: 1,
            confirmationCheckedError: false,
            limitedConfirmation: false,
            trialType: "limited",
            verification,
            waiting: false,
            loading: false
          });
        } else {
          setState({
            ...state,
            screen: 2,
            trialType: "limited",
            verification,
            waiting: false,
            loading: false
          });
        }
      } else {
        setState(state => ({
          ...state,
          screen: 0,
          loading: false
        }));
      }
    }
  }, [state.user]);

  React.useEffect(() => {
    if (state.verification) {
      if (state.verification === "success" && state.user.verificationStatus !== "success") {
        setState({
          ...state,
          trialType: "limited",
          errorMessage: state.user.verificationStatus ? { code: state.user.verificationStatus } : {}
        });
      }
    } else {
      setState({
        ...state,
        errorMessage: {}
      });
    }
  }, [state.screen, state.step]);

  // const handleShowChat = () => {
  //   window.ZohoHCAsap && window.ZohoHCAsap.Action("open");
  // };

  const errorMessage = (
    <>
      <div ref={errorElm} className="red-c" style={{ marginTop: 10 }}>
        ERROR-110: We have detected that this SellerID:{" "}
        <strong className="red-c">{state.errorSellerId}</strong> is already connected to a different
        account: <strong className="red-c">{state.errorEmail}</strong>. If you forgot your password,
        click{" "}
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            window.open(
              "https://support.asinzen.com/portal/en/kb/articles/how-do-i-change-or-reset-my-password",
              "_blank"
            )
          }
        >
          here
        </span>{" "}
        to learn how to reset it. If you have any other issues please click{" "}
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => window.open("https://asinzen.com/contactus", "_blank")}
        >
          here
        </span>{" "}
        to chat with a Customer Champion.
      </div>
      <br />
      <div
        onClick={copyErrorMessage}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <ContentCopyIcon />
        &nbsp;Copy Error Message
      </div>
    </>
  );

  const getStepContent = index => {
    let content = null;
    switch (index) {
      case 0:
        content = <div>Email: {state.user.email}</div>;
        break;
      case 1:
        content = (
          <div className={classes.spStatus}>
            Locate any marketplaces from the list below that you have an active Amazon Professional
            Seller account on, then click Connect to start the step-by-step Amazon Integration
            process. Make sure to follow the onscreen instructions carefully. Once completed, the
            connect button will display Connected successfully, click Next to move to the next step.
            {REGIONS.map(item => {
              const params = getAuthParams();
              return (
                <div key={item.shortName}>
                  <Paper className={classes.paper2}>
                    <div>
                      <strong className={classes.status}>{item.title}</strong>
                    </div>
                    <div className={classes.authorizeBtn}>
                      {state.authorizedRegions.includes(item.shortName) !== null ? (
                        <>
                          {state.authorizedRegions.includes(item.shortName) ? (
                            <strong className="has-text-primary connected-text">
                              Connected successfully
                            </strong>
                          ) : (
                            <>
                              <p className="red-c">Not connected</p>
                              <p>Click button below to connect.</p>
                              <button
                                className="button is-small is-info"
                                onClick={() => {
                                  authorizeSellerCentral({
                                    url: `${item.authUrl}?${params}&state=signup`,
                                    regionShortName: item.shortName
                                  });
                                }}
                              >
                                Connect
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        "..."
                      )}
                    </div>
                    {state.authorizeSpError &&
                    state.authorizeSpError.toLowerCase() === item.shortName.toLowerCase()
                      ? errorMessage
                      : null}
                  </Paper>
                </div>
              );
            })}
            <Button
              style={{ marginTop: 24, float: "right" }}
              color="primary"
              variant="contained"
              onClick={() => setState({ ...state, step: 2 })}
              disabled={!state.authorizedRegions.length}
            >
              Next
            </Button>
            <Button
              style={{ marginTop: 24, float: "right", marginRight: 12, boxShadow: "unset" }}
              color="primary"
              variant="outlined"
              onClick={() =>
                setState({
                  ...state,
                  step: 0,
                  screen: 0,
                  trialType: "",
                  confirmationChecked: false,
                  confirmationCheckedError: false,
                  verification: ""
                })
              }
            >
              Back
            </Button>
          </div>
        );
        break;
      case 2:
        content =
          localStorage.getItem("registerFrom") === "AZINSIGHT" ? (
            <>
              <p>
                a. Open the Google Chrome browser &#40;Unsure if you have Chrome? Click{" "}
                <span
                  style={{ color: "#485fc7", cursor: "pointer" }}
                  onClick={() =>
                    window.ZohoHCAsap &&
                    window.ZohoHCAsap.kbArticles.view({ articleId: "640662000007554801" })
                  }
                >
                  <b>here</b>
                </span>{" "}
                to learn how to verify you are using the Chrome browser.&#41;
              </p>
              <p>
                b. Click{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.CHROME_EXTENSION_DOWNLOAD_LINK}`}
                >
                  <b>here</b>
                </a>{" "}
                to download and install the AZInsight Chrome extension if not already installed on
                your browser
              </p>
              <Grid
                item
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  padding: 8,
                  marginTop: 12,
                  maxWidth: 800
                }}
              >
                <EmojiObjectsIcon /> You can manage your account, subscribe to a plan, or complete
                the Amazon Integration at any time during your trial by heading to the asinzen web
                portal{" "}
                <a target="_blank" rel="noopener noreferrer" href="https://app.asinzen.com">
                  <b>https://app.asinzen.com</b>
                </a>
              </Grid>
            </>
          ) : (
            <p>
              a.{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.bulkscanpro.com/linkstoaccess"
              >
                <b>Click here</b>
              </a>{" "}
              to go to the bulk scan pro resource page to access the link to download the desktop
              app or access the web app
            </p>
          );
        break;
    }
    return content;
  };

  if (state.loading) {
    return <span>Loading...</span>;
  }

  if (state.error) {
    return <span>{state.error}</span>;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={10}
      className={classes.container}
      style={{
        width: "inherit",
        paddingTop: "10%"
      }}
    >
      {state.screen === 0 ? (
        <>
          <Grid
            container
            spacing={10}
            className={classes.screen0}
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px"
              }}
            >
              {state.registerFrom === "AZINSIGHT" ? (
                <>
                  <p>
                    We’ve made it easy to give AZInsight a try by offering you two types of trials.
                    If you have an Amazon professional seller account, we recommend you select the
                    unlimited usage trial; otherwise you will get limited usage of the trial.
                  </p>
                  <br />
                  <br />
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <RadioGroup aria-label="status" name="status" value={state.trialType}>
                        <FormControlLabel
                          value="unlimited"
                          control={
                            <Radio
                              color="primary"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />
                          }
                          label={
                            <div>
                              <div style={{ fontWeight: "bold" }}>Unlimited usage trial</div>
                              <div style={{ fontSize: 14 }}>
                                You are required to complete Amazon Integration using an Amazon
                                professional seller account. There is no limit on how many products
                                you can evaluate during the trial for the unlimited trial. After
                                your trial ends, you can subscribe to the monthly plan or yearly
                                plan.
                              </div>
                            </div>
                          }
                          onClick={() =>
                            setState({
                              ...state,
                              trialType: "unlimited",
                              screen: 1,
                              step: 1
                            })
                          }
                        />
                        <br />
                        <br />

                        <FormControlLabel
                          value="limited"
                          control={
                            <Radio
                              color="primary"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />
                          }
                          label={
                            <div>
                              <div style={{ fontWeight: "bold" }}>Limited usage trial</div>
                              <div style={{ fontSize: 14 }}>
                                You are NOT required to complete Amazon Integration and do not need
                                an Amazon professional seller account. To combat free trial fraud,
                                you will be required to use a Credit Card to get started with the
                                limited free trial . The limited trial will ONLY allow you to
                                evaluate a total of 40 products for the duration of the trial, no
                                matter how long the trial is. After your trial ends, you can
                                subscribe to the monthly plan or complete the Amazon Integration to
                                subscribe to the monthly or yearly plan.
                              </div>
                            </div>
                          }
                          onClick={() =>
                            setState({
                              ...state,
                              trialType: "limited",
                              limitedConfirmation: true
                            })
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <Grid
                      item
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: 4,
                        padding: 16
                      }}
                    >
                      <p>
                        <span style={{ display: "block" }}>
                          <strong>
                            Why do I need to complete the Amazon integration to get an unlimited
                            trial?
                          </strong>
                        </span>
                        <span style={{ display: "block" }}>
                          To provide you with accurate product-related data, AZInsight relies on
                          information obtained from the official Amazon integration. Every Amazon
                          Professional seller account has access to this integration but requires
                          you to grant permission to an app developer to utilize this service.
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <RadioGroup aria-label="status" name="status" value={state.trialType}>
                        <FormControlLabel
                          value="unlimited"
                          control={
                            <Radio
                              color="primary"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />
                          }
                          label={
                            <div>
                              <div style={{ fontWeight: "bold" }}>Unlimited usage Beta</div>
                              <div style={{ fontSize: 14 }}>
                                You are required to complete Amazon Integration using an Amazon
                                professional seller account. There is no limit on how many products
                                you can evaluate during beta.
                              </div>
                            </div>
                          }
                          onClick={() =>
                            setState({
                              ...state,
                              trialType: "unlimited",
                              screen: 1,
                              step: 1
                            })
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <Grid
                      item
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: 4,
                        padding: 16
                      }}
                    >
                      <p>
                        <span style={{ display: "block" }}>
                          <strong>
                            Why do I need to complete the Amazon integration to get an unlimited
                            usage Beta?
                          </strong>
                        </span>
                        <span style={{ display: "block" }}>
                          You are required to complete Amazon Integration using an Amazon
                          professional seller account. There is no limit on how many products you
                          can evaluate during the trial for the unlimited trial.
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {state.limitedConfirmation ? (
            <AlertDialogSlide
              open
              isDisagree
              isAgree
              handleClose={() =>
                setState({
                  ...state,
                  limitedConfirmation: false,
                  trialType: "",
                  confirmationChecked: false,
                  confirmationCheckedError: false
                })
              }
              handleDisagre={() =>
                setState({
                  ...state,
                  limitedConfirmation: false,
                  trialType: "",
                  confirmationChecked: false,
                  confirmationCheckedError: false
                })
              }
              handleAgree={() => {
                if (state.confirmationChecked) {
                  setState({
                    ...state,
                    screen: 2,
                    confirmationCheckedError: false,
                    limitedConfirmation: false
                  });
                } else {
                  setState({ ...state, confirmationCheckedError: true });
                }
              }}
              title={<div>Confirm limited usage trial</div>}
              content={
                <div className="limited-trial-content">
                  <p>
                    Please keep in mind the limited trial will only allow you to evaluate{" "}
                    <strong>40 ASINs</strong> and you will be required to use a credit card to begin
                    the trial. If you evaluate <strong>40 ASINS</strong> before the 10 days is over,
                    the trial will expire. If you have an Amazon Seller Pro account, we suggest you
                    choose the unlimited usage trial which requires you to complete the Amazon
                    integration and does not require you to enter your credit card to use the trial.
                  </p>
                  <FormControlLabel
                    className="checkbox-confirm"
                    onChange={() =>
                      setState({
                        ...state,
                        confirmationChecked: !state.confirmationChecked,
                        confirmationCheckedError: state.confirmationChecked
                      })
                    }
                    checked={state.confirmationChecked}
                    control={<Checkbox className="checkbox" color="primary" />}
                    label={
                      <p style={{ fontSize: 14 }}>
                        I understand that I've selected the limited trial which will only allow me
                        to evaluate 40 ASINs.
                      </p>
                    }
                  />
                  {state.confirmationCheckedError && (
                    <p style={{ color: "red" }}>
                      You must confirm that you understand to continue.
                    </p>
                  )}
                </div>
              }
              textDisagree="No, go back"
              textAgree="I understand, continue"
            />
          ) : null}
        </>
      ) : state.screen === 1 ? (
        <Stepper activeStep={state.step} orientation="vertical" className={classes.steper}>
          {state.steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        index === 1 && state.trialType === "limited"
                          ? "Your account was verified"
                          : label
                    }}
                  />
                  {label.indexOf("as a developer on Amazon Seller Central to generate an M") >=
                    0 && (
                    <div>
                      <span
                        className="wistia_embed wistia_async_fbvm4bb56s popover=true popoverContent=link"
                        style={{ display: "inline", position: "relative" }}
                      >
                        Having issues adding asinzen as a developer on Seller Central?{" "}
                        <a href="#">Click here to watch this short video.</a>
                      </span>
                    </div>
                  )}
                </StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div>
                      {state.step === 1 && state.trialType === "limited" && (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: 10 }}
                          onClick={() => setState({ ...state, step: 2 })}
                        >
                          Cancel, I will do the limited trial
                        </Button>
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      ) : (
        <Grid
          container
          spacing={10}
          className={classes.smsContainer}
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              background: "#fff"
            }}
          >
            <p>
              <strong>
                To prevent free trial abuse and maintain the quality of our service, we require you
                to verify your account by using a valid credit card. Your credit card will NOT be
                charged and is securely processed by Stripe payment processor. The purpose of
                providing a credit card is to activate your trial. If you don't wish to signup using
                a credit card and you have an Amazon Professional Seller account, click the Back
                button and select the Unlimited Usage trial.
              </strong>
            </p>
            <br />
            <p>
              <strong>FAQ</strong>
              <br />
              <br />
              Q: I don't have a Professional Amazon Seller Account at this time?
              <br />
              A: You can verify your account using a credit card. After your trial is over, you can
              subscribe to the monthly plan which does not require a Professional Seller Account.
              <br />
              <br />
              Q: I don't feel safe giving you my credit card information for a trial.
              <br />
              A: If you have an Amazon Professional account, you don't have to: click the back
              button and choose the Unlimited Trial.
              <br />
              <br />
              Q: I am a Virtual Assistant and don't have a Professional Seller Account or a credit
              card.
              <br />
              A: Unfortunately, our service was not designed for free usage by non Amazon sellers.
              Consider working for an Amazon Seller to ask them to get access to the software.
              <br />
              <br />
              Q: My question or concern isn't listed here. <br />
              A:{" "}
              <a
                onClick={() => window.ZohoHCAsap && window.ZohoHCAsap.Action("open")}
                className="pointer"
              >
                Click here
              </a>{" "}
              to talk to a customer champion.
            </p>

            <br />
            <br />
            <p>
              <strong>
                Step 1: Activate your free limited trial by using a valid credit card.
              </strong>
            </p>
            <Grid container spacing={2} className={classes.verification}>
              <Grid item xs={12} sm={6} className={classes.verificationButton}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendVerificationCode}
                    style={{ marginRight: 10, marginTop: 10, width: 240 }}
                    disabled={state.waiting}
                  >
                    {state.waiting ? "Processing..." : "Verify Credit Card"}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  {state.errorMessage?.code ? (
                    <strong className="has-text-danger">
                      <em>{CC_VERIFICATION_ERROR[state.errorMessage.code]}</em>
                    </strong>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setState({
                      ...state,
                      screen: 0,
                      step: 0,
                      trialType: "",
                      confirmationChecked: false,
                      confirmationCheckedError: false,
                      verification: ""
                    })
                  }
                  style={{ marginRight: 10, width: 80 }}
                  className={classes.buttonBack}
                  disabled={state.waiting}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(UserStyles)(
  connectAutoDispatch(
    state => ({ userInfo: state.userState.userInfo }),
    {}
  )(withSnackbar(LoginWithAmazon))
);
