import React from "react";
import _ from "lodash";

import ProductItem from "components/plans/ProductItem";
import ChromeStoreIcon from "static/img/chromeicon.png";
import AppStoreIcon from "static/img/appstoreicon.png";
import GooglePlayIcon from "static/img/googleplayicon.png";
import moment from "moment";

export default function AZInsightPlan({
  data,
  prices,
  classes,
  interval,
  planTab,
  userInfo,
  changeInterval,
  intervalLables,
  features,
  openRenewConfirmation,
  openCheckoutUrl,
  openErrorDialog
}) {
  const {
    stripePriceKey,
    isInTrial,
    isNotStartTrial,
    isOutOfTrial,
    plan,
    productType,
    startTrialTime,
    freeTrialType,
    startedFreeTrialAt,
    isNotSpToken
  } = userInfo;
  const availablePlans = data
    .map(d => {
      const priorities = {
        HAZability_PLAN_MONTHLY: 1,
        HAZability_PLAN: 2,
        AZInsight_PLAN_MONTHLY: 3,
        AZInsight_PLAN: 4
      };
      const price = prices.data.find(price => price.metadata && price.metadata.key === d.id);
      if (price) {
        let hide = true;
        let upgrade = false;
        if (!stripePriceKey || stripePriceKey === price.metadata.key) {
          hide = false;
        } else if (
          priorities[price.metadata.key] > priorities[stripePriceKey] &&
          stripePriceKey.replace("_MONTHLY", "") !== price.metadata.key.replace("_MONTHLY", "")
        ) {
          upgrade = true;
        }

        return {
          ...d,
          hide,
          upgrade,
          subscribed: stripePriceKey === price.metadata.key,
          priceId: price.id,
          key: price.metadata.key,
          mode: price.type === "recurring" ? "subscription" : "payment"
        };
      }
      return d;
    })
    .filter(d => d.priceId && (!d.hide || d.upgrade));

  const plans = _.groupBy(
    _.orderBy(availablePlans, "amount").filter(item => item.interval !== "Lifetime"),
    "interval"
  );

  const intervals = Object.keys(plans).reverse();
  const availableIntervals = intervals.filter(interval => plans[interval]);
  let activeInterval = interval;
  if (availableIntervals.length === 1) {
    [activeInterval] = availableIntervals;
  }

  const isStartTrial = !plan && !productType && isInTrial;
  const loginToStartTrial = !isInTrial && isNotStartTrial && !isOutOfTrial;
  const isEndTrial = !plan && !productType && isOutOfTrial;

  return (
    <>
      <div className="tabs is-toggle is-centered">
        <ul>
          {intervals
            .filter(interval => plans[interval])
            .map(i => (
              <li key={i} className={`${i === activeInterval ? "is-active" : ""}`}>
                <a onClick={() => changeInterval(i)}>{intervalLables[i.toLowerCase()]}</a>
              </li>
            ))}
        </ul>
      </div>
      {isStartTrial && (startTrialTime || startedFreeTrialAt) ? (
        !isNotSpToken ? (
          <div className="is-trial">
            <h1>You're currently on the AZInsight Advanced 30-day free trial.</h1>
            <div>
              <p className="start">
                Your free trial started on{" "}
                {moment(startTrialTime || startedFreeTrialAt)
                  .local()
                  .format("MMM-DD-YYYY")}
              </p>
              <p className="end">
                Your free trial will end on{" "}
                {moment(startTrialTime || startedFreeTrialAt)
                  .local()
                  .add(freeTrialType === "30" ? 30 : 10, "days")
                  .format("MMM-DD-YYYY")}
              </p>
            </div>
          </div>
        ) : (
          <div className="trial-not-sp">
            <h1>You're currently on the AZInsight Advanced Limited trial</h1>
            <div>
              <p className="end">Your Trial will end once you reach 40 ASINs</p>
            </div>
          </div>
        )
      ) : null}

      {loginToStartTrial || (isStartTrial && !(startTrialTime || startedFreeTrialAt)) ? (
        <div className="not-yet-trial">
          <h1>
            You have not started the free trial, to start your AZInsight Advanced free trial, make
            sure that you have installed AZInsight on your Chrome browser by{" "}
            <a
              href="https://support.asinzen.com/portal/en/kb/articles/how-to-install-the-azinsight-chrome-extension"
              target="_blank"
              rel="noopener noreferrer"
            >
              clicking here
            </a>
            .
          </h1>
        </div>
      ) : null}

      {isEndTrial ? (
        <div className="end-trial">
          <h1>
            Your free trial has ended, you can continue using AZInsight Advanced by subscribing
            below.
          </h1>
        </div>
      ) : null}

      <div className="note-az-plans">Promo codes or discounts do not apply to monthly plans</div>

      <div className="pricing-table is-comparative">
        <div className="pricing-plan is-features">
          <div className="plan-header">Features</div>
          <div className="plan-price">
            <span className="plan-price-amount">&nbsp;</span>
          </div>
          <div className="plan-items">
            {features[planTab].map((feature, index) => (
              <div className="plan-item" key={index}>
                {feature.name}{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className={feature.name.toLowerCase().replace(/\W+/g, "_")}
                  id={feature.name.toLowerCase().replace(/\W+/g, "_")}
                >
                  <i className="fa fa-info-circle" aria-hidden="true" />
                </span>
              </div>
            ))}
          </div>
          <div className="plan-footer">
            <span style={{ color: "#7a7a7a", fontSize: "0.9em" }}>
              Not sure what is the best plan for you? Click{" "}
              <a
                href="https://support.asinzen.com/portal/en/kb/articles/what-is-the-difference-between-the-azinsight-plus-and-the-pro-plan"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to learn differences between the Starter and the Advanced.
            </span>
          </div>
        </div>
        {plans[activeInterval] &&
          plans[activeInterval]
            .map(d => {
              const priorities = {
                HAZability_PLAN_MONTHLY: 1,
                HAZability_PLAN: 2,
                AZInsight_PLAN_MONTHLY: 3,
                AZInsight_PLAN: 4
              };
              const price = prices.data.find(
                price => price.metadata && price.metadata.key === d.id
              );
              if (price) {
                let hide = true;
                let upgrade = false;
                if (!stripePriceKey || stripePriceKey === price.metadata.key) {
                  hide = false;
                } else if (priorities[price.metadata.key] > priorities[stripePriceKey]) {
                  upgrade = true;
                }
                return {
                  ...d,
                  hide,
                  upgrade,
                  subscribed: stripePriceKey === price.metadata.key,
                  priceId: price.id,
                  key: price.metadata.key,
                  mode: price.type === "recurring" ? "subscription" : "payment"
                };
              }
              return d;
            })
            .filter(d => d.priceId && (!d.hide || d.upgrade))
            .map((d, k) => (
              <ProductItem
                onUpdateSubscription={openRenewConfirmation}
                onSubscribe={openCheckoutUrl}
                key={k}
                product={d}
                features={features[planTab]}
                userInfo={userInfo}
                showError={openErrorDialog}
              />
            ))}
      </div>
      <div className="pricing-table is-comparative">
        <div className={`${classes.dowloadWrapper} pricing-plan`}>
          <div>
            <strong>Chrome Extension</strong>
          </div>
          <div className={classes.downloadIcon}>
            <a
              href="https://chrome.google.com/webstore/detail/azinsight-amazon-fba-prod/gefiflkplklbfkcjjcbobokclopbigfg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ChromeStoreIcon} />
            </a>
          </div>
        </div>
        <div className={`${classes.dowloadWrapper} pricing-plan`}>
          <div>
            <strong>Mobile Phone Apps</strong>
          </div>
          <div className={classes.downloadIcon}>
            <a
              href="https://apps.apple.com/us/app/scanez/id1523943349"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStoreIcon} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.asinzen.ezscan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlayIcon} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
