import React from "react";
import { TableCell, TableRow, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import RemoveIcon from "@material-ui/icons/DeleteForever";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function ListMember({
  record,
  index,
  tabValue,
  editMember,
  accessHistoryMember,
  recordSourcingHistoryMember,
  selectMarketplace,
  handleGetUserData,
  sendEmailInstructions,
  handleDeleteMember,
  classes
}) {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={record._id}>
      <TableCell padding="checkbox">{index + 1}</TableCell>
      <TableCell component="td" scope="row" padding="none">
        {`${record.firstName} ${record.lastName}`}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {record.email}
      </TableCell>
      {tabValue === "AZINSIGHT" ? (
        <TableCell component="td" scope="row" padding="none">
          <FormControlLabel
            checked={record.disabledCalculatorSettings}
            control={
              <Checkbox
                onChange={e => editMember(e, record)}
                className="checkbox"
                value={record.disabledCalculatorSettings}
                color="primary"
              />
            }
            label=""
          />
        </TableCell>
      ) : null}
      {tabValue === "AZINSIGHT" ? (
        <TableCell component="td" scope="row" padding="none">
          <FormControlLabel
            checked={record.accessHistory}
            control={
              <Checkbox
                onChange={e => accessHistoryMember(e, record)}
                className="checkbox"
                value={record.accessHistory}
                color="primary"
              />
            }
            label=""
          />
        </TableCell>
      ) : null}
      {tabValue === "AZINSIGHT" ? (
        <TableCell component="td" scope="row" padding="none">
          <FormControlLabel
            checked={record.recordSourcingHistory}
            control={
              <Checkbox
                onChange={e => recordSourcingHistoryMember(e, record)}
                className="checkbox"
                value={record.recordSourcingHistory}
                color="primary"
              />
            }
            label=""
          />
        </TableCell>
      ) : null}
      {tabValue === "AZINSIGHT" ? (
        <TableCell component="td" scope="row" padding="none">
          <span style={{ textTransform: "capitalize" }}>{record.status || "Active"}</span>
        </TableCell>
      ) : null}
      <TableCell component="td" scope="row" padding="none">
        {record.createdAt ? moment(record.createdAt).format("ll") : ""}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {tabValue === "AZINSIGHT" ? (
          <Tooltip title="Clone the Calculator Settings from the main account to this account">
            <CopyIcon
              onClick={() => selectMarketplace(record)}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
          </Tooltip>
        ) : null}
        <EditIcon
          onClick={handleGetUserData(record)}
          style={{ cursor: "pointer", marginRight: "5px" }}
        />
        <RemoveIcon
          onClick={handleDeleteMember(record)}
          style={{ cursor: "pointer", marginRight: "5px", color: "red" }}
          className={classes.btnDelete}
        />
        {tabValue === "AZINSIGHT" ? (
          <MailOutlineIcon
            onClick={() => sendEmailInstructions(record)}
            style={{ cursor: "pointer", marginRight: "5px" }}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
}
