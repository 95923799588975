/*eslint-disable*/
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertDialogSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
    this.ref = React.createRef(null);
  }

  render() {
    const {
      handleClose,
      textAgree,
      isDisagree,
      textDisagree,
      handleDisagre,
      isAgree,
      handleAgree,
      isHtml,
      title,
      content
    } = this.props;

    return (
      <Dialog
        ref={this.ref}
        open={this.state.open}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {!isHtml ? title : <div dangerouslySetInnerHTML={{ __html: title }} />}
        </DialogTitle>
        <DialogContent>
          {!isHtml ? content : <DialogContentText dangerouslySetInnerHTML={{ __html: content }} />}
        </DialogContent>
        <DialogActions>
          {!isDisagree ? (
            ""
          ) : (
            <Button onClick={() => handleDisagre()} color="primary">
              {textDisagree}
            </Button>
          )}
          {!isAgree ? (
            ""
          ) : (
            <Button onClick={() => handleAgree()} color="primary">
              {textAgree}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialogSlide;
