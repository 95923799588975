import { createActions } from "redux-actions";

export const UserActionType = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  USER_SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",

  USER_VALIDATE_MWS_REQUEST: "USER_VALIDATE_MWS_REQUEST",
  USER_VALIDATE_MWS_SUCCESS: "USER_VALIDATE_MWS_SUCCESS",
  USER_VALIDATE_MWS_FAILURE: "USER_VALIDATE_MWS_FAILURE",

  USER_GET_LIST_MEMBER_REQUEST: "USER_GET_LIST_MEMBER_REQUEST",
  USER_GET_LIST_MEMBER_SUCCESS: "USER_GET_LIST_MEMBER_SUCCESS",
  USER_GET_LIST_MEMBER_FAILURE: "USER_GET_LIST_MEMBER_FAILURE",

  USER_ADD_NEW_MEMBER_REQUEST: "USER_ADD_NEW_MEMBER_REQUEST",
  USER_ADD_NEW_MEMBER_SUCCESS: "USER_ADD_NEW_MEMBER_SUCCESS",
  USER_ADD_NEW_MEMBER_FAILURE: "USER_ADD_NEW_MEMBER_FAILURE",

  USER_EDIT_MEMBER_REQUEST: "USER_EDIT_MEMBER_REQUEST",
  USER_EDIT_MEMBER_SUCCESS: "USER_EDIT_MEMBER_SUCCESS",
  USER_EDIT_MEMBER_FAILURE: "USER_EDIT_MEMBER_FAILURE",

  USER_ACCESS_HISTORY_REQUEST: "USER_ACCESS_HISTORY_REQUEST",
  USER_ACCESS_HISTORY_SUCCESS: "USER_ACCESS_HISTORY_SUCCESS",
  USER_ACCESS_HISTORY_FAILURE: "USER_ACCESS_HISTORY_FAILURE",

  USER_RECORD_SOURCING_HISTORY_REQUEST: "USER_RECORD_SOURCING_HISTORY_REQUEST",
  USER_RECORD_SOURCING_HISTORY_SUCCESS: "USER_RECORD_SOURCING_HISTORY_SUCCESS",
  USER_RECORD_SOURCING_HISTORY_FAILURE: "USER_RECORD_SOURCING_HISTORY_FAILURE",

  USER_ACTIVE_MEMBER_REQUEST: "USER_ACTIVE_MEMBER_REQUEST",
  USER_ACTIVE_MEMBER_SUCCESS: "USER_ACTIVE_MEMBER_SUCCESS",
  USER_ACTIVE_MEMBER_FAILURE: "USER_ACTIVE_MEMBER_FAILURE",

  GET_USER_INFO_APP_REQUEST: "GET_USER_INFO_APP_REQUEST",
  GET_USER_INFO_APP_SUCCESS: "GET_USER_INFO_APP_SUCCESS",
  GET_USER_INFO_APP_FAILURE: "GET_USER_INFO_APP_FAILURE",

  USER_RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
  USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  USER_UPDATE_INFO_WITHOUT_MWS_REQUEST: "USER_UPDATE_INFO_WITHOUT_MWS_REQUEST",
  USER_UPDATE_INFO_WITHOUT_MWS_SUCCESS: "USER_UPDATE_INFO_WITHOUT_MWS_SUCCESS",
  USER_UPDATE_INFO_WITHOUT_MWS_FAILURE: "USER_UPDATE_INFO_WITHOUT_MWS_FAILURE",

  USER_UPDATE_MWS_REQUEST: "USER_UPDATE_MWS_REQUEST",
  USER_UPDATE_MWS_SUCCESS: "USER_UPDATE_MWS_SUCCESS",
  USER_UPDATE_MWS_FAILURE: "USER_UPDATE_MWS_FAILURE",

  USER_DELETE_MEMBER_REQUEST: "USER_DELETE_MEMBER_REQUEST",
  USER_DELETE_MEMBER_SUCCESS: "USER_DELETE_MEMBER_SUCCESS",
  USER_DELETE_MEMBER_FAILURE: "USER_DELETE_MEMBER_FAILURE",

  USER_FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",

  USER_GET_LIST_MARKETPLACE_REQUEST: "USER_GET_LIST_MARKETPLACE_REQUEST",
  USER_GET_LIST_MARKETPLACE_SUCCESS: "USER_GET_LIST_MARKETPLACE_SUCCESS",
  USER_GET_LIST_MARKETPLACE_FAILURE: "USER_GET_LIST_MARKETPLACE_FAILURE",

  USER_UPDATE_MARKETPLACE_REQUEST: "USER_UPDATE_MARKETPLACE_REQUEST",
  USER_UPDATE_MARKETPLACE_SUCCESS: "USER_UPDATE_MARKETPLACE_SUCCESS",
  USER_UPDATE_MARKETPLACE_FAILURE: "USER_UPDATE_MARKETPLACE_FAILURE",

  USER_CLEAR_MARKETPLACE_REQUEST: "USER_CLEAR_MARKETPLACE_REQUEST",
  USER_CLEAR_MARKETPLACE_SUCCESS: "USER_CLEAR_MARKETPLACE_SUCCESS",
  USER_CLEAR_MARKETPLACE_FAILURE: "USER_CLEAR_MARKETPLACE_FAILURE",

  USER_CHANGE_PASSWORD_REQUEST: "USER_CHANGE_PASSWORD_REQUEST",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  USER_CHANGE_PASSWORD_FAILURE: "USER_CHANGE_PASSWORD_FAILURE",

  USER_CHANGE_EMAIL_REQUEST: "USER_CHANGE_EMAIL_REQUEST",
  USER_CHANGE_EMAIL_SUCCESS: "USER_CHANGE_EMAIL_SUCCESS",
  USER_CHANGE_EMAIL_FAILURE: "USER_CHANGE_EMAIL_FAILURE",

  USER_CANCEL_SUBSCRIPTION_PLAN_REQUEST: "USER_CANCEL_SUBSCRIPTION_PLAN_REQUEST",
  USER_CANCEL_SUBSCRIPTION_PLAN_SUCCESS: "USER_CANCEL_SUBSCRIPTION_PLAN_SUCCESS",
  USER_CANCEL_SUBSCRIPTION_PLAN_FAILURE: "USER_CANCEL_SUBSCRIPTION_PLAN_FAILURE",

  USER_REGISTER_ACCOUNT_REQUEST: "USER_REGISTER_ACCOUNT_REQUEST",
  USER_REGISTER_ACCOUNT_SUCCESS: "USER_REGISTER_ACCOUNT_SUCCESS",
  USER_REGISTER_ACCOUNT_FAILURE: "USER_REGISTER_ACCOUNT_FAILURE",

  SEND_EMAIL_VERIFY_REQUEST: "SEND_EMAIL_VERIFY_REQUEST",
  SEND_EMAIL_VERIFY_SUCCESS: "SEND_EMAIL_VERIFY_SUCCESS",
  SEND_EMAIL_VERIFY_FAILURE: "SEND_EMAIL_VERIFY_FAILURE",

  USER_VALIDATE_MWS_TOKEN_REQUEST: "USER_VALIDATE_MWS_TOKEN_REQUEST",
  USER_VALIDATE_MWS_TOKEN_SUCCESS: "USER_VALIDATE_MWS_TOKEN_SUCCESS",
  USER_VALIDATE_MWS_TOKEN_FAILURE: "USER_VALIDATE_MWS_TOKEN_FAILURE",

  USER_COPY_CALCULATOR_SETTINGS_REQUEST: "USER_COPY_CALCULATOR_SETTINGS_REQUEST",
  USER_COPY_CALCULATOR_SETTINGS_SUCCESS: "USER_COPY_CALCULATOR_SETTINGS_SUCCESS",
  USER_COPY_CALCULATOR_SETTINGS_FAILURE: "USER_COPY_CALCULATOR_SETTINGS_FAILURE",

  USER_SEND_INSTRUCTIONS_REQUEST: "USER_SEND_INSTRUCTIONS_REQUEST",
  USER_SEND_INSTRUCTIONS_SUCCESS: "USER_SEND_INSTRUCTIONS_SUCCESS",
  USER_SEND_INSTRUCTIONS_FAILURE: "USER_SEND_INSTRUCTIONS_FAILURE",

  USER_AUTHORIZE_MWS_REQUEST: "USER_AUTHORIZE_MWS_REQUEST",
  USER_AUTHORIZE_MWS_SUCCESS: "USER_AUTHORIZE_MWS_SUCCESS",
  USER_AUTHORIZE_MWS_FAILURE: "USER_AUTHORIZE_MWS_FAILURE",

  USER_AUTHORIZE_MWS_UPDATE_REQUEST: "USER_AUTHORIZE_MWS_UPDATE_REQUEST",
  USER_AUTHORIZE_MWS_UPDATE_SUCCESS: "USER_AUTHORIZE_MWS_UPDATE_SUCCESS",
  USER_AUTHORIZE_MWS_UPDATE_FAILURE: "USER_AUTHORIZE_MWS_UPDATE_FAILURE",

  USER_SEND_PHONE_NUMBER_REQUEST: "USER_SEND_PHONE_NUMBER_REQUEST",
  USER_SEND_PHONE_NUMBER_SUCCESS: "USER_SEND_PHONE_NUMBER_SUCCESS",
  USER_SEND_PHONE_NUMBER_FAILURE: "USER_SEND_PHONE_NUMBER_FAILURE",

  USER_VERIFY_CODE_REQUEST: "USER_VERIFY_CODE_REQUEST",
  USER_VERIFY_CODE_SUCCESS: "USER_VERIFY_CODE_SUCCESS",
  USER_VERIFY_CODE_FAILURE: "USER_VERIFY_CODE_FAILURE"
};

export const { userLoginRequest, userLoginSuccess, userLoginFailure } = createActions({
  [UserActionType.USER_LOGIN_REQUEST]: payload => payload,
  [UserActionType.USER_LOGIN_SUCCESS]: payload => payload,
  [UserActionType.USER_LOGIN_FAILURE]: payload => payload
});

export const { userSignupRequest, userSignupSuccess, userSignupFailure } = createActions({
  [UserActionType.USER_SIGNUP_REQUEST]: payload => payload,
  [UserActionType.USER_SIGNUP_SUCCESS]: payload => payload,
  [UserActionType.USER_SIGNUP_FAILURE]: payload => payload
});

export const {
  userValidateMwsRequest,
  userValidateMwsSuccess,
  userValidateMwsFailure
} = createActions({
  [UserActionType.USER_VALIDATE_MWS_REQUEST]: payload => payload,
  [UserActionType.USER_VALIDATE_MWS_SUCCESS]: payload => payload,
  [UserActionType.USER_VALIDATE_MWS_FAILURE]: payload => payload
});

export const {
  userGetListMemberRequest,
  userGetListMemberSuccess,
  userGetListMemberFailure
} = createActions({
  [UserActionType.USER_GET_LIST_MEMBER_REQUEST]: payload => payload,
  [UserActionType.USER_GET_LIST_MEMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_GET_LIST_MEMBER_FAILURE]: payload => payload
});

export const {
  userAddNewMemberRequest,
  userAddNewMemberSuccess,
  userAddNewMemberFailure
} = createActions({
  [UserActionType.USER_ADD_NEW_MEMBER_REQUEST]: payload => payload,
  [UserActionType.USER_ADD_NEW_MEMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_ADD_NEW_MEMBER_FAILURE]: payload => payload
});

export const {
  userEditMemberRequest,
  userEditMemberSuccess,
  userEditMemberFailure
} = createActions({
  [UserActionType.USER_EDIT_MEMBER_REQUEST]: payload => payload,
  [UserActionType.USER_EDIT_MEMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_EDIT_MEMBER_FAILURE]: payload => payload
});

export const {
  userAccessHistoryRequest,
  userAccessHistorySuccess,
  userAccessHistoryFailure
} = createActions({
  [UserActionType.USER_ACCESS_HISTORY_REQUEST]: payload => payload,
  [UserActionType.USER_ACCESS_HISTORY_SUCCESS]: payload => payload,
  [UserActionType.USER_ACCESS_HISTORY_FAILURE]: payload => payload
});

export const {
  userRecordSourcingHistoryRequest,
  userRecordSourcingHistorySuccess,
  userRecordSourcingHistoryFailure
} = createActions({
  [UserActionType.USER_RECORD_SOURCING_HISTORY_REQUEST]: payload => payload,
  [UserActionType.USER_RECORD_SOURCING_HISTORY_SUCCESS]: payload => payload,
  [UserActionType.USER_RECORD_SOURCING_HISTORY_FAILURE]: payload => payload
});

export const {
  userActiveMemberRequest,
  userActiveMemberSuccess,
  userActiveMemberFailure
} = createActions({
  [UserActionType.USER_ACTIVE_MEMBER_REQUEST]: payload => payload,
  [UserActionType.USER_ACTIVE_MEMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_ACTIVE_MEMBER_FAILURE]: payload => payload
});

export const {
  getUserInfoAppRequest,
  getUserInfoAppSuccess,
  getUserInfoAppFailure
} = createActions({
  [UserActionType.GET_USER_INFO_APP_REQUEST]: payload => payload,
  [UserActionType.GET_USER_INFO_APP_SUCCESS]: payload => payload,
  [UserActionType.GET_USER_INFO_APP_FAILURE]: payload => payload
});

export const {
  userResetPasswordRequest,
  userResetPasswordSuccess,
  userResetPasswordFailure
} = createActions({
  [UserActionType.USER_RESET_PASSWORD_REQUEST]: payload => payload,
  [UserActionType.USER_RESET_PASSWORD_SUCCESS]: payload => payload,
  [UserActionType.USER_RESET_PASSWORD_FAILURE]: payload => payload
});

export const {
  userUpdateInfoWithoutMwsRequest,
  userUpdateInfoWithoutMwsSuccess,
  userUpdateInfoWithoutMwsFailure
} = createActions({
  [UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_REQUEST]: payload => payload,
  [UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_SUCCESS]: payload => payload,
  [UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_FAILURE]: payload => payload
});

export const { userUpdateMwsRequest, userUpdateMwsSuccess, userUpdateMwsFailure } = createActions({
  [UserActionType.USER_UPDATE_MWS_REQUEST]: payload => payload,
  [UserActionType.USER_UPDATE_MWS_SUCCESS]: payload => payload,
  [UserActionType.USER_UPDATE_MWS_FAILURE]: payload => payload
});

export const {
  userDeleteMemberRequest,
  userDeleteMemberSuccess,
  userDeleteMemberFailure
} = createActions({
  [UserActionType.USER_DELETE_MEMBER_REQUEST]: payload => payload,
  [UserActionType.USER_DELETE_MEMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_DELETE_MEMBER_FAILURE]: payload => payload
});

export const {
  userForgotPasswordRequest,
  userForgotPasswordSuccess,
  userForgotPasswordFailure
} = createActions({
  [UserActionType.USER_FORGOT_PASSWORD_REQUEST]: payload => payload,
  [UserActionType.USER_FORGOT_PASSWORD_SUCCESS]: payload => payload,
  [UserActionType.USER_FORGOT_PASSWORD_FAILURE]: payload => payload
});

export const {
  userGetListMarketplaceRequest,
  userGetListMarketplaceSuccess,
  userGetListMarketplaceFailure
} = createActions({
  [UserActionType.USER_GET_LIST_MARKETPLACE_REQUEST]: payload => payload,
  [UserActionType.USER_GET_LIST_MARKETPLACE_SUCCESS]: payload => payload,
  [UserActionType.USER_GET_LIST_MARKETPLACE_FAILURE]: payload => payload
});

export const {
  userUpdateMarketplaceRequest,
  userUpdateMarketplaceSuccess,
  userUpdateMarketplaceFailure
} = createActions({
  [UserActionType.USER_UPDATE_MARKETPLACE_REQUEST]: payload => payload,
  [UserActionType.USER_UPDATE_MARKETPLACE_SUCCESS]: payload => payload,
  [UserActionType.USER_UPDATE_MARKETPLACE_FAILURE]: payload => payload
});

export const {
  userClearMarketplaceRequest,
  userClearMarketplaceSuccess,
  userClearMarketplaceFailure
} = createActions({
  [UserActionType.USER_CLEAR_MARKETPLACE_REQUEST]: payload => payload,
  [UserActionType.USER_CLEAR_MARKETPLACE_SUCCESS]: payload => payload,
  [UserActionType.USER_CLEAR_MARKETPLACE_FAILURE]: payload => payload
});

export const {
  userChangePasswordRequest,
  userChangePasswordSuccess,
  userChangePasswordFailure
} = createActions({
  [UserActionType.USER_CHANGE_PASSWORD_REQUEST]: payload => payload,
  [UserActionType.USER_CHANGE_PASSWORD_SUCCESS]: payload => payload,
  [UserActionType.USER_CHANGE_PASSWORD_FAILURE]: payload => payload
});

export const {
  userChangeEmailRequest,
  userChangeEmailSuccess,
  userChangeEmailFailure
} = createActions({
  [UserActionType.USER_CHANGE_EMAIL_REQUEST]: payload => payload,
  [UserActionType.USER_CHANGE_EMAIL_SUCCESS]: payload => payload,
  [UserActionType.USER_CHANGE_EMAIL_FAILURE]: payload => payload
});

export const {
  userCancelSubscriptionPlanRequest,
  userCancelSubscriptionPlanSuccess,
  userCancelSubscriptionPlanFailure
} = createActions({
  [UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_REQUEST]: payload => payload,
  [UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_SUCCESS]: payload => payload,
  [UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_FAILURE]: payload => payload
});

export const {
  userRegisterAccountRequest,
  userRegisterAccountSuccess,
  userRegisterAccountFailure
} = createActions({
  [UserActionType.USER_REGISTER_ACCOUNT_REQUEST]: payload => payload,
  [UserActionType.USER_REGISTER_ACCOUNT_SUCCESS]: payload => payload,
  [UserActionType.USER_REGISTER_ACCOUNT_FAILURE]: payload => payload
});

export const {
  sendEmailVerifyRequest,
  sendEmailVerifySuccess,
  sendEmailVerifyFailure
} = createActions({
  [UserActionType.SEND_EMAIL_VERIFY_REQUEST]: payload => payload,
  [UserActionType.SEND_EMAIL_VERIFY_SUCCESS]: payload => payload,
  [UserActionType.SEND_EMAIL_VERIFY_FAILURE]: payload => payload
});

export const {
  userValidateMwsTokenRequest,
  userValidateMwsTokenSuccess,
  userValidateMwsTokenFailure
} = createActions({
  [UserActionType.USER_VALIDATE_MWS_TOKEN_REQUEST]: payload => payload,
  [UserActionType.USER_VALIDATE_MWS_TOKEN_SUCCESS]: payload => payload,
  [UserActionType.USER_VALIDATE_MWS_TOKEN_FAILURE]: payload => payload
});

export const {
  userCopyCalculatorSettingsRequest,
  userCopyCalculatorSettingsSuccess,
  userCopyCalculatorSettingsFailure
} = createActions({
  [UserActionType.USER_COPY_CALCULATOR_SETTINGS_REQUEST]: payload => payload,
  [UserActionType.USER_COPY_CALCULATOR_SETTINGS_SUCCESS]: payload => payload,
  [UserActionType.USER_COPY_CALCULATOR_SETTINGS_FAILURE]: payload => payload
});

export const {
  userSendInstructionsRequest,
  userSendInstructionsSuccess,
  userSendInstructionsFailure
} = createActions({
  [UserActionType.USER_SEND_INSTRUCTIONS_REQUEST]: payload => payload,
  [UserActionType.USER_SEND_INSTRUCTIONS_SUCCESS]: payload => payload,
  [UserActionType.USER_SEND_INSTRUCTIONS_FAILURE]: payload => payload
});

export const {
  userAuthorizeMwsRequest,
  userAuthorizeMwsSuccess,
  userAuthorizeMwsFailure
} = createActions({
  [UserActionType.USER_AUTHORIZE_MWS_REQUEST]: payload => payload,
  [UserActionType.USER_AUTHORIZE_MWS_SUCCESS]: payload => payload,
  [UserActionType.USER_AUTHORIZE_MWS_FAILURE]: payload => payload
});

export const {
  userAuthorizeMwsUpdateRequest,
  userAuthorizeMwsUpdateSuccess,
  userAuthorizeMwsUpdateFailure
} = createActions({
  [UserActionType.USER_AUTHORIZE_MWS_UPDATE_REQUEST]: payload => payload,
  [UserActionType.USER_AUTHORIZE_MWS_UPDATE_SUCCESS]: payload => payload,
  [UserActionType.USER_AUTHORIZE_MWS_UPDATE_FAILURE]: payload => payload
});

export const {
  userSendPhoneNumberRequest,
  userSendPhoneNumberSuccess,
  userSendPhoneNumberFailure
} = createActions({
  [UserActionType.USER_SEND_PHONE_NUMBER_REQUEST]: payload => payload,
  [UserActionType.USER_SEND_PHONE_NUMBER_SUCCESS]: payload => payload,
  [UserActionType.USER_SEND_PHONE_NUMBER_FAILURE]: payload => payload
});

export const {
  userVerifyCodeRequest,
  userVerifyCodeSuccess,
  userVerifyCodeFailure
} = createActions({
  [UserActionType.USER_VERIFY_CODE_REQUEST]: payload => payload,
  [UserActionType.USER_VERIFY_CODE_SUCCESS]: payload => payload,
  [UserActionType.USER_VERIFY_CODE_FAILURE]: payload => payload
});
