import React, { PureComponent } from "react";
import { SnackBarStl } from "./SnackBars.styled";

export default class SnackBars extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isOpenSnack || false,
      vertical: props.verticalSnack || "bottom",
      horizontal: props.horizontalSnack || "right",
      message: props.messageSnack || ""
    };
  }

  handleRequestClose = () => {
    this.setState({ open: false });
    if (this.props.handleStopSnackBar) {
      this.props.handleStopSnackBar();
    }
  };

  render() {
    const { vertical, horizontal, open, message } = this.state;
    const { duration } = this.props;
    return (
      <SnackBarStl
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={this.handleRequestClose}
        message={<span id="message-id">{message}</span>}
        autoHideDuration={duration || 5000}
      />
    );
  }
}
