/*eslint-disable*/
import moment from "moment";
import update from "immutability-helper";
import axios from "axios";
import numeral from "numeral";
import shallowEqual from "./shallowEqual";

function createUserInfoStore(userInfo) {
  return {
    ...userInfo.user,
    token: userInfo.token,
    logined: true
  };
}

function getDeepObject(object, defaultValue, ...keysDeep) {
  let cloneObject = object;
  let value = defaultValue;

  for (let i = 0; i < keysDeep.length; i++) {
    const keyDeep = keysDeep[i];
    if (i > 0) {
      if (cloneObject[keyDeep]) {
        value = cloneObject[keyDeep];
        cloneObject = cloneObject[keyDeep];
      }
    } else if (object && object[keyDeep]) {
      value = object[keyDeep];
      cloneObject = object[keyDeep];
    } else {
      value = defaultValue;
    }
  }
  return value;
}

function isEmptyObject(obj) {
  if (typeof obj === "undefined" || obj === null) return true;
  return Object.keys(obj).length === 0;
}

function validateNumber(rule, value, callback) {
  const val = parseInt(value, 10);
  if (Number.isNaN(val)) {
    callback(rule.message);
  } else {
    callback();
  }
}

function parseDataToListId(data, name, key) {
  if (!data || data.length === 0) return [];
  const result = [];
  data.map(item => {
    result.push(item[name][key]);
  });
  return result;
}

function reverseDataType(type = "", data) {
  if (type === "moment") {
    return !data ? null : moment(data);
  }
  if (type === "string") {
    return String(data);
  }
  if (type === "number") {
    return Number(data);
  }
  if (type === "boolean") {
    return Boolean(data);
  }
  return data;
}

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function safeJsonParse(str, initial = []) {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return initial;
  }
  return result || initial;
}

function passWithProps(source, pattern) {
  let obj = {};
  pattern.map(item => {
    if (typeof source[item] !== "undefined") {
      obj = update(obj, {
        [item]: {
          $set: source[item]
        }
      });
    }
  });
  return obj;
}

function calculatorPointToStar(point) {
  let result = [];
  if (point <= 0) {
    result = ["", "", "", "", ""];
  } else if (0 < point && point <= 10) {
    result = ["isHalf", "", "", "", ""];
  } else if (10 < point && point <= 20) {
    result = ["isFull", "", "", "", ""];
  } else if (20 < point && point <= 35) {
    result = ["isFull", "isHalf", "", "", ""];
  } else if (35 < point && point <= 50) {
    result = ["isFull", "isFull", "", "", ""];
  } else if (50 < point && point <= 100) {
    result = ["isFull", "isFull", "isHalf", ""];
  } else if (100 < point && point <= 150) {
    result = ["isFull", "isFull", "isFull", "", ""];
  } else if (150 < point && point <= 225) {
    result = ["isFull", "isFull", "isFull", "isHalf", ""];
  } else if (150 < point && point <= 225) {
    result = ["isFull", "isFull", "isFull", "isFull", ""];
  } else if (225 < point && point <= 300) {
    result = ["isFull", "isFull", "isFull", "isFull", "isHalf"];
  } else {
    result = ["isFull", "isFull", "isFull", "isFull", "isFull"];
  }
  return result;
}

function sortWorkerWinTheBid(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}

function formatDate(date) {
  const formatDate = new Date(date);
  return `${formatDate.getDate()}/${formatDate.getMonth() + 1}/${formatDate.getFullYear()}`;
}

function getSizeUserAvatar(size) {
  switch (size) {
    case "xsmall":
      return 30;
    case "small":
      return 50;
    case "medium":
      return 70;
    case "large":
      return 100;
    default:
      break;
  }
}

function generateAvatarFromName(name) {
  if (name.length <= 0) {
    return;
  }
  const arr = name.split(" ");
  const initials = arr[0].charAt(0);
  return initials.toUpperCase();
}

function generateColorCode(string) {
  const x = unescape(encodeURIComponent(string));
  let h = "";
  for (let i = 0; i < string.length; i++) {
    h += x.charCodeAt(i).toString(16);
  }
  if (h.length === 1) {
    return `${h}3`;
  }
  if (h.length === 2) {
    return `${h}35`;
  }
  return `${h}`;
}

const saveLocalStorage = async (key, data) => {
  if (localStorage) {
    await localStorage.setItem(key, data);
  }
};

const formatNumber = (n, isDecimal, isShow, numFixed) => {
  if (isNaN(Number(n)) || isNaN(parseFloat(n))) return "0.00";

  const num = Number((Math.round(parseFloat(n) * 100) / 100).toFixed(numFixed || 2));

  if (num <= 0 && isShow === true) {
    return isDecimal === true ? "0.00" : "0";
  }

  return num.toLocaleString("en", {
    minimumFractionDigits: isDecimal === true ? numFixed || 2 : 0
  });
};

export const checkExtensionInstalled = async () => {
  try {
    const t = await axios.get(`chrome-extension://${process.env.EXTENSION_ID}/images/icon-96.png`);
    if (t.status === 200 && t.data) {
      return true;
    }
  } catch (e) {
    console.log(" eeeee ", e);
  }
  return false;
};

export const checkHAZExtensionInstalled = async () => {
  try {
    const t = await axios.get(`chrome-extension://${process.env.HAZ_EXTENSION_ID}/test.png`);
    // console.log('checkHAZExtensionInstalled ', t);
    if (t.status === 200 && t.data) {
      return true;
    }
  } catch (e) {
    console.log(" eeeee ", e);
  }
  return false;
};

const isMyScriptLoaded = url => {
  const scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === url) return true;
  }
  return false;
};

const format = (text, format) => {
  const f = format || "0,0.00";
  return numeral(text).format(f);
};

const CONF = {
  US: {
    domain: "www.amazon.com"
  },
  CA: {
    domain: "www.amazon.ca"
  },
  MX: {
    domain: "www.amazon.mx"
  },
  UK: {
    domain: "www.amazon.co.uk"
  },
  DE: {
    domain: "www.amazon.de"
  },
  IT: {
    domain: "www.amazon.it"
  },
  FR: {
    domain: "www.amazon.fr"
  },
  ES: {
    domain: "www.amazon.es"
  }
};

const shortenMarketplaces = [
  {
    name: "United States",
    value: "US"
  },
  {
    name: "United Kingdom",
    value: "UK"
  },
  {
    name: "Canada",
    value: "CA"
  },
  {
    name: "Germany",
    value: "DE"
  }
];

const removeCommaInNumber = n => {
  const arrComma = (n && n.split && n.split(",")) || [];
  let c = 0;
  if (arrComma.length > 0) {
    do {
      n = n && n.replace && n.replace(",", "");
      c++;
    } while (c < arrComma.length);
  }
  return n;
};

export const convertToLocalNumber = (n, isDecimal, isShow, numFixed) => {
  n = removeCommaInNumber(n && typeof n === "number" ? n.toString() : n);
  if (isNaN(Number(n)) || isNaN(parseFloat(n))) return "0.00";

  const num = Number(parseFloat(n).toFixed(numFixed || 2));
  if (numFixed && numFixed === 4 && num === 0) {
    return Number(parseFloat(n).toFixed(6));
  }
  if (num <= 0 && isShow === true) {
    return isDecimal === true ? "0.00" : "0";
  }
  return num.toLocaleString("en", {
    minimumFractionDigits: isDecimal === true ? numFixed || 2 : 0
  });
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export {
  saveLocalStorage,
  formatNumber,
  passWithProps,
  safeJsonParse,
  randomInt,
  parseDataToListId,
  reverseDataType,
  getDeepObject,
  createUserInfoStore,
  isEmptyObject,
  validateNumber,
  shallowEqual,
  calculatorPointToStar,
  sortWorkerWinTheBid,
  formatDate,
  getSizeUserAvatar,
  generateAvatarFromName,
  generateColorCode,
  isMyScriptLoaded,
  format,
  CONF,
  shortenMarketplaces
};

export default {
  saveLocalStorage,
  formatNumber,
  passWithProps,
  safeJsonParse,
  randomInt,
  parseDataToListId,
  reverseDataType,
  getDeepObject,
  createUserInfoStore,
  isEmptyObject,
  validateNumber,
  shallowEqual,
  calculatorPointToStar,
  sortWorkerWinTheBid,
  formatDate,
  getSizeUserAvatar,
  generateAvatarFromName,
  generateColorCode,
  isMyScriptLoaded,
  format,
  CONF,
  shortenMarketplaces
};
