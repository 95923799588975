import React, { PureComponent } from "react";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";

export default class InputText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, inputId, data, onChange, inputLabel, type, autoFocus, fullWidth, startAdornment } = this.props;
    return (
      <FormControl className={className} fullWidth={fullWidth}>
        {inputLabel && (
          <InputLabel style={{ transform: "unset", fontSize: 15 }} htmlFor={inputId}>
            {inputLabel}
          </InputLabel>
        )}
        <Input
          placeholder={inputLabel}
          type={type || "string"}
          id={inputId}
          autoFocus={autoFocus || false}
          value={data}
          onChange={onChange}
          style={{ fontSize: 15 }}
          startAdornment={startAdornment}
        />
      </FormControl>
    );
  }
}

InputText.defaultProps = {
  fullWidth: true,
  autoFocus: true,
  onChange: null,
  inputId: "INPUT_KEY_ID",
  inputLabel: "",
  type: "",
  className: ""
};

InputText.propTypes = {
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
};
